import { Controller } from 'react-hook-form';
import { format } from 'date-fns';
import { isNil, noop } from 'lodash';
import { space } from 'styled-system';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { backgroundStyle, borderStyle, focusStyle, sizeStyle } from '../styles';
import { Box } from '../../../grid';
import { Calendar } from '../../../calendar';
import { Calendar as CalendarIcon } from '../../../icons';
import { ErrorLabel } from '../error-label';
import { useFormState } from '../..';

const TAB = 9;

const formatDateForDisplay = (date) => (date ? format(new Date(date), 'd LLL yyyy') : '');

const DatePicker = ({ dateFormat, disabledDays, name, showErrorPoppedOut, validation, ...props }) => {
  const { control, watch } = useFormState();

  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // Refs for later reference
  const popoutHasFocus = useRef(false);

  const { getError } = useFormState();

  const error = getError(name);

  const showPicker = () => {
    setIsOpen(true);
  };

  const hidePicker = () => {
    popoutHasFocus.current = false;
    if (isOpen) {
      setIsOpen(false);
    }
  };

  // Don't allow user to enter any chars with the keyboard, unless its a tab
  const onKeyDown = (e) => {
    if (e.keyCode === TAB) {
      hidePicker();
    } else {
      e.preventDefault();
      showPicker();
    }
  };

  // Show datepicker on focus
  const handleInputFocus = () => {
    setFocus(true);
    showPicker();
  };

  // On input blur, hide the datepicker if not focussed
  const handleInputBlur = () => {
    setFocus(false);
    // setTimeout required to ensure blur event doesn't kick in before overlay focus
    setTimeout(() => {
      if (!popoutHasFocus.current) {
        hidePicker();
      }
    }, 1);
  };

  const handleDayClick = (onChange) => (day) => {
    hidePicker();

    const value = format(day, dateFormat);

    onChange(value);
  };

  const handlePopoutFocus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    popoutHasFocus.current = true;
  };

  const handlePopoutBlur = () => {
    // We need to set a timeout otherwise IE will hide the overlay when
    // focusing it
    setTimeout(() => {
      popoutHasFocus.current = false;
      hidePicker();
    }, 150);
  };

  const selectedDate = watch(name);

  const modifiers = { day: selectedDate };

  return (
    <Controller
      control={control}
      defaultValue={selectedDate}
      name={name}
      rules={validation}
      render={({ field: { onChange, value } }) => (
        <Box position="relative">
          <Box position="relative">
            <StyledDatePicker
              hasError={!isNil(error)}
              hasFocus={hasFocus}
              hasHover={hasHover}
              name={`${name}-datepicker`}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onChange={noop}
              onKeyDown={(e) => onKeyDown(e)}
              value={formatDateForDisplay(value)}
              placeholder="Select date"
              {...props}
            />
            <StyledCalendarIcon onClick={handleInputFocus} />
          </Box>
          <Popout isVisible={isOpen} onFocus={(e) => handlePopoutFocus(e)} onBlur={() => handlePopoutBlur()}>
            <Calendar
              disabledDays={disabledDays}
              name={name}
              dateRange={false}
              numberOfMonths={1}
              onChange={handleDayClick(onChange)}
              modifiers={modifiers}
              value={value}
            />
          </Popout>
          {error && <ErrorLabel showPoppedOut={showErrorPoppedOut}>{error}</ErrorLabel>}
        </Box>
      )}
    />
  );
};

const StyledDatePicker = styled.input`
  ${backgroundStyle};
  ${borderStyle};
  ${focusStyle};
  ${sizeStyle};
  ${space};

  font-size: ${({ theme }) => theme.fontSize.base};

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border-color: ${theme.colors.dangerSeven};
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greyTwo};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyFour};
    opacity: 1;
  }
`;

const Popout = styled(Box)`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 12px 8px 24px rgba(0, 0, 0, 0.1), -12px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  font-size: ${({ theme }) => theme.fontSize.small};
  overflow-y: auto;
  width: fit-content;
  min-width: 286px;
  position: absolute;
  z-index: 5;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  cursor: pointer;

  path {
    fill: ${(props) => props.theme.colors.black};
  }
`;

DatePicker.propTypes = {
  dateFormat: PropTypes.string,
  disabledDays: PropTypes.arrayOf(PropTypes.shape()),
  name: PropTypes.string.isRequired,
  validation: PropTypes.shape(),
  showErrorPoppedOut: PropTypes.bool,
};

DatePicker.defaultProps = {
  dateFormat: "yyyy-MM-dd'T'HH:mm:ss",
  disabledDays: [],
  validation: null,
  showErrorPoppedOut: false,
};

export { DatePicker };
