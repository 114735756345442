import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { icons, responsiveStyles, useMounted } from '../ui-library';
import { useRootStore } from '../store-provider/lib';

const { mobileOnly } = responsiveStyles;
const { LoadingIndicator } = icons;

const PopDownloadLink = ({ popID, variant, children }) => {
  const { ShipmentStore } = useRootStore();
  const [isFetching, setIsFetching] = useState(false);
  const mounted = useMounted();

  // eslint-disable-next-line no-use-before-define
  const Component = variant === 'detail' ? DetailViewLink : ListViewLink;

  const onClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      const url = await ShipmentStore.getShipmentPop(popID);

      if (url) {
        const a = document.createElement('a');
        a.href = url;
        // @TODO: Need to test that this works with real files from the API.
        a.download = true;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();
      }
    } catch (error) {
      // eslint-disable-next-line no-alert
      window.alert('Proof of pick up document is not available for this shipment.');
    }
  };

  return (
    <Component
      onClick={async (event) => {
        setIsFetching(true);
        await onClick(event);
        if (mounted) {
          setIsFetching(false);
        }
      }}
    >
      {isFetching ? <LoadingIndicator /> : children}
    </Component>
  );
};

const DetailViewLink = styled.div`
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.interactionSix};
  width: 50px;

  ${mobileOnly} {
    margin-top: auto;
    margin-left: 12px;
  }
`;

const ListViewLink = styled.div`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.interactionSix};
  height: 24px;
  :hover {
    background: ${({ theme }) => theme.colors.greyTwo};
  }
`;

PopDownloadLink.propTypes = {
  children: PropTypes.node.isRequired,
  popID: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['detail', 'list']),
};

PopDownloadLink.defaultProps = {
  variant: 'list',
};

export { PopDownloadLink };
