import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useRootStore } from '../store-provider/lib';

const ExpandableList = ({ array }) => {
  const { ShipmentStore } = useRootStore();

  const { keyword } = ShipmentStore;

  const [expanded, setExpanded] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setExpanded(!expanded);
  };

  if (!array || array.length === 0) {
    return null;
  }

  if (expanded) {
    return (
      <div>
        {array.map((value) => (
          <Item key={value} bold={value === keyword}>
            {value}
          </Item>
        ))}

        <Button key="button" onClick={handleClick} type="button">
          Show Less
        </Button>
      </div>
    );
  }

  return (
    <div>
      {array.map((value, index) => {
        if (index < 3) {
          return (
            <Item key={value} bold={value === keyword}>
              {value}
            </Item>
          );
        }

        if (index === 3) {
          return (
            <Button key="button" onClick={handleClick} type="button">
              Show More
            </Button>
          );
        }

        return null;
      })}
    </div>
  );
};

export { ExpandableList };

ExpandableList.propTypes = {
  array: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Button = styled.button`
  color: ${({ theme }) => theme.colors.interactionSix};
  font-weight: bold;
`;

const Item = styled.div`
  padding: 0.25rem 0 0.25rem 0;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;
