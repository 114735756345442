import { isEmpty, upperCase } from 'lodash';

import { TextLink } from '../../components/text-link';

const EXPECTED_EMPTY_VALUE = 'NUL';
const AMOUNT = 1;

const processReferenceNumbers = (referenceNumbers) => {
  const isMissing = isEmpty(referenceNumbers) || upperCase(referenceNumbers) === EXPECTED_EMPTY_VALUE;
  if (isMissing) {
    return { isMissing, firstNumber: 'N/A' };
  }
  const isArray = Array.isArray(referenceNumbers);
  return { isMissing, isArray, referenceNumbers, firstNumber: isArray ? referenceNumbers[0] : referenceNumbers };
};

const renderReferenceNumbers = (
  referenceNumbers,
  emptyValueString = '',
  keyPrefix = '',
  seeAllOnClick = () => null
) => {
  const { isMissing, isArray } = processReferenceNumbers(referenceNumbers);

  if (isMissing) {
    return emptyValueString;
  }

  if (isArray) {
    return referenceNumbers.slice(0, AMOUNT + 1).map((number, index) => {
      const key = `${keyPrefix} ${number}`;
      if (index >= AMOUNT) {
        return <TextLink key={key} text="See all" onClick={seeAllOnClick} />;
      }

      return <div key={key}>{number}</div>;
    });
  }

  return referenceNumbers;
};

export { renderReferenceNumbers, processReferenceNumbers };
