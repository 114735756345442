import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CasePropType } from '../../../prop-types';
import { caseStatuses } from '../../../constants/statuses';
import { getBrandStatus } from '../../../utils/get-brand-status';
import { getStatus } from '../../../utils/get-status';
import { Link } from '../../link';
import { RelativeTimeStamp } from '../../relative-time-stamp';
import { StatusLabel, Table } from '../../ui-library';
import { useBranding } from '../../../contexts/branding';
import { useFilterContext } from '../../../contexts/filter';

const { TableRow, TableCell } = Table;

// Formats the column values, particularly date related ones
const formatValue = (branding, { apiField, id }, data, selectedOption) => {
  // TODO: handle some of this friendly name mapping elsewhere?
  switch (id) {
    case 'caseType':
      return data.caseTypeName;
    case 'caseDeliveryState':
      return data.deliveryStateName;
    case 'contractID':
      return data.contractName;
    case 'lastModifiedOn': {
      const date = data[apiField];

      return <RelativeTimeStamp date={date} showDistanceString={selectedOption !== 'Resolved'} />;
    }
    case 'createdOn': {
      const date = data[apiField];

      if (!date) return null;

      return <RelativeTimeStamp date={`${date}Z`} showDistanceString={selectedOption !== 'Resolved'} />;
    }
    case 'keyContact':
      return data.primaryContactName;
    case 'caseNumber': {
      const { statusName = null } = data;

      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <StyledCell
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          {statusName && (
            <StatusLabel type="case" variant={getStatus(caseStatuses, statusName).variant} mb="0.5rem">
              {getBrandStatus(branding, statusName)}
            </StatusLabel>
          )}
          {data.caseNumber}
        </StyledCell>
      );
    }
    default:
      return data[apiField];
  }
};

const CaseRow = ({ columns, data }) => {
  const branding = useBranding();

  const { caseID } = data;

  const { filters, normalisedColumns } = useFilterContext();

  const { caseStatus } = filters;

  // We need to find the current selection for the `caseStatus` filter.
  // If this is 'Active', we don't want to include the status label in the case listing view.
  const { caseStatusFilter = {} } = normalisedColumns;

  const { options = [] } = caseStatusFilter;

  const selectedOption = options.find((option) => option.value === caseStatus);

  if (selectedOption?.text !== 'Active') {
    // if the selected filter is not Active, remove the caseStatus value from the data object
    // as it should not be displayed
    // eslint-disable-next-line no-param-reassign
    delete data.statusName;
  }

  const dataProxy = new Proxy(data, {
    get(target, prop) {
      if (prop === 'casePriority') {
        return target?.casePriorityName;
      }

      return Reflect.get(target, prop);
    },
  });

  return (
    <TableRow
      as={Link}
      // It would be nice if this could be the CaseNumber, but it's used elsewhere
      // for the API lookup which expects CaseId.
      to={`/cases/${caseID}`}
      draggable="false"
    >
      {columns
        // do not include caseStatus as a column
        .filter((column) => column.id !== 'status')
        .map((column) => {
          if (column.id === 'caseDeliveryState')
            return (
              <TableCell key={column.id} style={{ textAlign: 'center' }}>
                {formatValue(branding, column, dataProxy, selectedOption?.text) || '–'}
              </TableCell>
            );
          return (
            <TableCell key={column.id}>
              {formatValue(branding, column, dataProxy, selectedOption?.text) || '–'}
            </TableCell>
          );
        })}
    </TableRow>
  );
};

const StyledCell = styled.div`
  cursor: auto;
  user-select: text;
  white-space: nowrap;
`;

CaseRow.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, title: PropTypes.string, apiField: PropTypes.string })
  ).isRequired,
  data: PropTypes.shape(CasePropType).isRequired,
};

export { CaseRow };
