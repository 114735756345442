import { Controller } from 'react-hook-form';
import { createPortal } from 'react-dom';
import { get } from 'lodash';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import { BookingFormRow } from '../booking-form-row';
import { DynamicFormInput } from '../../dynamic-form-input';
import { form, Radio } from '../../ui-library';
import { ListItem } from '../list-item';
import { SpecialEquipmentForm } from './special-equipment-form';
import { useHydrateFormValue } from '../use-hydrate-form-value';
import { useModalState } from '../../modal-context';

const { Form, useFormState } = form;

const SpecialEquipment = () => {
  const { control } = useFormState();

  const { modalContainer } = useModalState();

  const formRef = useRef();

  useHydrateFormValue('equipments', []);

  const [specialEquipmentType, setSpecialEquipmentType] = useState('specialisedHandling');

  return (
    <Controller
      control={control}
      name="equipments"
      render={({ field: { onChange, onBlur, value = [] } }) => {
        const removeItem = (index) => {
          const newValue = [...value];

          newValue.splice(index, 1);

          onChange(newValue);
          onBlur();
        };

        const handleSubmit = (event) => {
          onChange([...value, ...event.equipments]);

          formRef.current.reset();

          onBlur();
        };

        return (
          <>
            {!!modalContainer &&
              createPortal(
                <BookingFormRow mb={0}>
                  <Wrapper>
                    <RadioWrapper>
                      <DynamicFormInput
                        name="packingEquipment"
                        render={({ label, options }) =>
                          options.length > 0 && (
                            <Radio
                              id="specialEquipmentType-packingEquipment"
                              selected={specialEquipmentType === 'packingEquipment'}
                              onClick={() => setSpecialEquipmentType('packingEquipment')}
                            >
                              {label}
                            </Radio>
                          )
                        }
                      />
                      <DynamicFormInput
                        name="specialisedHandling"
                        render={({ label, options }) =>
                          options.length > 0 && (
                            <Radio
                              id="specialEquipmentType-specialisedHandling"
                              selected={specialEquipmentType === 'specialisedHandling'}
                              onClick={() => setSpecialEquipmentType('specialisedHandling')}
                            >
                              {label}
                            </Radio>
                          )
                        }
                      />
                    </RadioWrapper>
                    <Form
                      mode="onChange"
                      onSubmit={handleSubmit}
                      // TODO: fix bug if options.length === 0 for default selection
                      ref={formRef}
                    >
                      <SpecialEquipmentForm specialEquipmentType={specialEquipmentType} />
                    </Form>
                  </Wrapper>
                </BookingFormRow>,
                modalContainer
              )}
            <BookingFormRow>
              <ItemWrapper>
                {value.map((item, index) => {
                  // we don't know which fields each special equipment will have, so try and fetch both
                  const specialEquipment = get(item, 'packingEquipment') || get(item, 'specialisedHandling');
                  const notes = get(item, 'notePackingEquipment') || get(item, 'noteSpecialHandling');

                  return (
                    <ListItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={`specialEquipment-${index}`}
                      name={specialEquipment}
                      description={notes}
                      actions={[
                        {
                          label: 'Remove',
                          onClick: () => {
                            removeItem(index);
                            onBlur();
                          },
                        },
                      ]}
                    />
                  );
                })}
              </ItemWrapper>
            </BookingFormRow>
          </>
        );
      }}
    />
  );
};

const ItemWrapper = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-auto-flow: row;
  margin-top: 1rem;
`;

const RadioWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-gap: 1rem;
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.greyOne};
  border: 1px solid ${({ theme }) => theme.colors.greyTwo};
  border-radius: 6px;

  display: grid;
  grid-column-gap: 2rem;
  grid-auto-flow: row;
  grid-template-columns: 1fr 2fr;

  padding: 2rem;

  textarea {
    min-height: 8rem;
  }
`;

export { SpecialEquipment };
