import { isEmpty, map, reverse } from 'lodash';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { attachmentPropType } from '../../prop-types';
import { Box } from '../grid';
import { Comment } from '../comment';
import { fontSize } from '../../constants';
import { H2, P } from '../type';

const CommentsList = ({ handleAttachmentDownload, comments }) => {
  const commentsListEndElement = useRef(null);

  useEffect(() => {
    const scrollToLatestComment = () => {
      commentsListEndElement.current?.scrollIntoView({ behaviour: 'smooth' });
    };
    scrollToLatestComment();
  }, [comments]);

  return !isEmpty(comments) ? (
    <>
      {map(
        reverse([...comments]),
        ({ portalCommentID, createdByCustomer, createdByLinfox, description, createdOn, documents }) => (
          <Comment
            key={portalCommentID}
            name={createdByCustomer || createdByLinfox}
            date={createdOn}
            fromLinfox={!createdByCustomer}
            attachments={documents || []}
            handleAttachmentDownload={handleAttachmentDownload}
          >
            {description &&
              description.split('\n').map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <P key={i} fontSize={fontSize.small} color="greyNine">
                  {item}
                </P>
              ))}
          </Comment>
        )
      )}
      <Box marginBottom="xl" ref={commentsListEndElement} />
    </>
  ) : (
    <Box flexDirection="column" justifyContent="center" margin="0 auto" maxWidth="50%" textAlign="center">
      <Box my="md">
        <NoCommentsSvg />
      </Box>
      <H2>No Comments</H2>
      <p>
        Someone will get back to you shortly. In the meantime, please add any additional information that you wish to
        communicate below.
      </p>
    </Box>
  );
};

const NoCommentsSvg = () => (
  <svg width="273" height="226" viewBox="0 0 273 226" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <circle cx="83.4793" cy="99.7762" r="10.9793" fill="#6FB2D9" />
      <rect x="101.778" y="97.0312" width="51.2367" height="5.48965" rx="2.43984" fill="#6FB2D9" />
      <rect x="101.778" y="110.756" width="154.32" height="26.8383" rx="2.43984" fill="#E7F6FE" />
      <rect x="109.098" y="118.075" width="51.8467" height="3.0498" rx="1.5249" fill="#6FB2D9" />
      <rect x="109.098" y="127.225" width="139.681" height="3.0498" rx="1.5249" fill="#6FB2D9" />
    </g>
    <circle cx="27.4793" cy="171.979" r="10.9793" fill="#A8AFB7" />
    <rect x="45.7783" y="169.234" width="51.2367" height="5.48965" rx="2.43984" fill="#A8AFB7" />
    <rect x="45.7793" y="182.958" width="154.32" height="26.8383" rx="2.43984" fill="#F4F5F6" />
    <rect x="53.0986" y="190.277" width="51.8467" height="3.0498" rx="1.5249" fill="#A8AFB7" />
    <rect x="53.0986" y="199.427" width="139.681" height="3.0498" rx="1.5249" fill="#A8AFB7" />
    <circle cx="27.4793" cy="26.9793" r="10.9793" fill="#A8AFB7" />
    <rect x="45.7783" y="24.2344" width="51.2367" height="5.48965" rx="2.43984" fill="#A8AFB7" />
    <rect x="45.7793" y="37.9585" width="114.673" height="26.8383" rx="2.43984" fill="#F4F5F6" />
    <rect x="53.0986" y="45.2778" width="51.8467" height="3.0498" rx="1.5249" fill="#A8AFB7" />
    <rect x="53.0986" y="54.4272" width="100.034" height="3.0498" rx="1.5249" fill="#A8AFB7" />
    <defs>
      <clipPath id="clip0">
        <rect width="183.598" height="48.7969" fill="white" transform="translate(72.5 88.7969)" />
      </clipPath>
    </defs>
  </svg>
);

CommentsList.propTypes = {
  handleAttachmentDownload: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      portalCommentID: PropTypes.string,
      createdByCustomer: PropTypes.string,
      createdByLinfox: PropTypes.string,
      createdOn: PropTypes.string,
      description: PropTypes.string,
      attachments: PropTypes.arrayOf(PropTypes.shape(attachmentPropType)),
    })
  ).isRequired,
};

export { CommentsList };
