import { get } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { constants } from '../ui-library';
import { deliveryStatuses } from '../../constants/statuses';
import { findMilestone } from '../../utils/find-milestone';
import { getIsFutile } from '../../utils/get-is-futile';
import { getIsInLastMile } from '../../utils/get-is-in-last-mile';
import { inferCurrentMilestone } from '../../utils/infer-current-milestone';
import { Map } from '../bing-map/map';
import { ShipmentMilestone } from './shipment-milestone';
import { shipmentPropType } from '../../prop-types/shipment';
import { useFeatureFlag } from '../../hooks/use-feature-flag';
import { useHasPrivilege } from '../../hooks/use-has-privilege';
import { useRootStore } from '../store-provider/lib';
import { Wrapper } from './styles';

const { contractPrivileges } = constants;

const SHIPMENT_STATUS_INDEXES = {
  CREATED: 0,
  PROCESSING: 1,
  IN_TRANSIT: 2,
  LAST_MILE: 2,
  DELIVERED: 3,
};

const ShipmentMilestones = ({ shipmentDetail }) => {
  const [showMap, setShowMap] = useState(false);

  const { ShipmentStore } = useRootStore();
  const lastMileFeatureEnabled = useFeatureFlag('LAST_MILE');
  const userHasLastMilePrivilege = useHasPrivilege(contractPrivileges.trackLastMile);
  const guestHasLastMileAnonymousKey = !!ShipmentStore.anonymousKey;
  const enableLastMile = lastMileFeatureEnabled && (userHasLastMilePrivilege || guestHasLastMileAnonymousKey);

  const isInLastMile = getIsInLastMile({ enableLastMile, shipmentDetail });

  const setTopGap = (statusInTransit) => {
    if (statusInTransit) {
      return '200px';
    }

    return '130px';
  };

  const setGap = setTopGap(isInLastMile);

  const shipmentIsCancelled =
    shipmentDetail.shipmentStatus === deliveryStatuses.cancelled.key ||
    findMilestone(deliveryStatuses.cancelled.key, shipmentDetail);

  const shipmentIsFutile = getIsFutile(shipmentDetail?.futileReference);

  const hasAccessToTrackTrace = useHasPrivilege(contractPrivileges.track);

  const currentMilestoneIndex = inferCurrentMilestone(shipmentDetail)?.index;

  const createdProps = {
    title: deliveryStatuses.created.label,
    actualDate: get(findMilestone(deliveryStatuses.created.key, shipmentDetail), 'eventDateTime'),
    index: 0,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
  };

  const processingProps = {
    title: deliveryStatuses.processing.label,
    actualDate: get(findMilestone(deliveryStatuses.processing.key, shipmentDetail), 'eventDateTime'),
    index: 1,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
  };

  const shipmentIsInTransit = currentMilestoneIndex >= SHIPMENT_STATUS_INDEXES.IN_TRANSIT;
  const inTransitProps = {
    title: deliveryStatuses.inTransit.label,
    actualDate: shipmentDetail.actualPickupDateTime,
    estimatedStartDate: shipmentDetail.plannedPickupDateTimeFrom,
    estimatedEndDate: shipmentDetail.plannedPickupDateTimeTo,
    index: 2,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
    isInTransit: true && currentMilestoneIndex === SHIPMENT_STATUS_INDEXES.IN_TRANSIT,
    popID: shipmentIsInTransit && hasAccessToTrackTrace ? shipmentDetail.shipmentId : null,
  };

  const lastMileProps = {
    title: deliveryStatuses.lastMile.label,
    actualDate: shipmentDetail.updatedLastTime,
    estimatedEndDate: shipmentDetail.eta,
    index: 2,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
    isInLastMile: true,
    setShowMap,
    popID: shipmentIsInTransit && hasAccessToTrackTrace ? shipmentDetail.shipmentId : null,
  };

  const shipmentIsDelivered = currentMilestoneIndex === SHIPMENT_STATUS_INDEXES.DELIVERED;

  const deliveredProps = {
    title: deliveryStatuses.delivered.label,
    actualDate: shipmentDetail.actualDeliveryDateTime,
    estimatedStartDate: shipmentDetail.plannedDeliveryDateTimeFrom,
    estimatedEndDate: shipmentDetail.plannedDeliveryDateTimeTo,
    index: 3,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
    podID: shipmentIsDelivered && hasAccessToTrackTrace ? shipmentDetail.shipmentId : null,
  };

  const futileProps = {
    title: deliveryStatuses.futile.label,
    actualDate: shipmentDetail.actualDeliveryDateTime,
    estimatedStartDate: shipmentDetail.plannedDeliveryDateTimeFrom,
    estimatedEndDate: shipmentDetail.plannedDeliveryDateTimeTo,
    index: 3,
    currentMilestoneIndex,
    isCancelled: shipmentIsCancelled,
  };

  const cancelledProps = {
    title: deliveryStatuses.cancelled.label,
    currentMilestoneIndex,
    index: 3,
    actualDate: get(findMilestone(deliveryStatuses.cancelled.key, shipmentDetail), 'eventDateTime'),
  };

  const getLastMilestoneProps = () => {
    if (shipmentIsCancelled) {
      return cancelledProps;
    }

    if (shipmentIsFutile) {
      return futileProps;
    }

    return deliveredProps;
  };

  return (
    <Wrapper minHeight={isInLastMile}>
      {!showMap && (
        <>
          <ShipmentMilestone {...createdProps} />
          <ShipmentMilestone {...processingProps} />
          <ShipmentMilestone {...(isInLastMile ? lastMileProps : inTransitProps)} />
          <ShipmentMilestone {...getLastMilestoneProps()} setGap={setGap} />
        </>
      )}
      {isInLastMile ? <Map showMap={showMap} setShowMap={setShowMap} /> : null}
    </Wrapper>
  );
};

ShipmentMilestones.propTypes = {
  shipmentDetail: shipmentPropType.isRequired,
  shipmentLocation: PropTypes.shape({
    Latitude: PropTypes.number,
    Longitude: PropTypes.number,
    deliveryLocation: PropTypes.shape({
      street: PropTypes.string,
      suburb: PropTypes.string,
      state: PropTypes.string,
    }),
  }),
  shipmentId: PropTypes.string.isRequired,
};

ShipmentMilestones.defaultProps = {
  shipmentLocation: null,
};

export { ShipmentMilestones };
