import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

import { Box, form } from '../../ui-library';
import { LabelledInput } from '../../labelled-input';
import { useRootStore } from '../../store-provider/lib';

const { useFormState } = form;

const BookingTemplateOptionForm = () => {
  const { BookingStore } = useRootStore();

  const { availableTemplates, fetchingTemplates } = BookingStore;

  const { setValue, watch } = useFormState();

  const contractID = watch('contractID');
  const serviceID = watch('serviceID');

  const contractOptions = useMemo(() => {
    return availableTemplates.map(({ name, contractId }) => ({ label: name, value: contractId }));
  }, [availableTemplates]);

  const serviceOptions = useMemo(() => {
    const contractTemplates = availableTemplates.find((value) => value.contractId === contractID);

    if (contractTemplates) {
      const { services = [] } = contractTemplates;

      return services.map(({ serviceID: value, serviceName: label }) => ({ label, value }));
    }

    return [];
  }, [availableTemplates, contractID]);

  const commodityOptions = useMemo(() => {
    const contractTemplates = availableTemplates.find((value) => value.contractId === contractID);

    if (contractTemplates) {
      const serviceTemplates = contractTemplates.services.find((value) => value.serviceID === serviceID);

      if (serviceTemplates) {
        const { commodities = [] } = serviceTemplates;
        return commodities.map(({ commodityID: value, commodityName: label }) => ({ label, value }));
      }

      return [];
    }

    return [];
  }, [availableTemplates, contractID, serviceID]);

  useEffect(() => {
    // Clear service and commodity selection when switching contracts.
    setValue('serviceID', '');
    setValue('commodityID', '');
  }, [contractID, contractOptions, setValue]);

  useEffect(() => {
    // Clear commodity selection when switching services.
    setValue('commodityID', '');
  }, [serviceID, serviceOptions, setValue]);

  return (
    <>
      <Box mb={4}>
        <LabelledInput
          id="contractID"
          type="select"
          label="Contract"
          name="contractID"
          isFetching={fetchingTemplates}
          disabled={!contractOptions.length}
          options={contractOptions}
          validation={{ required: 'Contract is required.' }}
        />
      </Box>
      <Box mb={4}>
        <LabelledInput
          id="serviceID"
          type="select"
          label="Service"
          name="serviceID"
          isFetching={fetchingTemplates}
          disabled={!serviceOptions.length}
          options={serviceOptions}
          validation={{ required: 'Service is required.' }}
        />
      </Box>
      <LabelledInput
        id="commodityID"
        type="select"
        label="Commodity"
        name="commodityID"
        isFetching={fetchingTemplates}
        disabled={!commodityOptions.length}
        options={commodityOptions}
        validation={{ required: 'Commodity is required.' }}
      />
    </>
  );
};

const ObservedBookingTemplateOptionForm = observer(BookingTemplateOptionForm);
export { ObservedBookingTemplateOptionForm as BookingTemplateOptionForm };
