/* eslint-disable no-unused-vars */
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MapWrapper } from '../../shipment-milestones/styles';
import { ShowMapComponent } from '..';
import { useMapState } from '../map-context';
import { useRootStore } from '../../store-provider/lib';

const Map = ({ showMap, setShowMap }) => {
  const { address, eta, lastUpdatedLocationTime, latitude, longitude, travelDistance } = useMapState();

  const [mapHasMounted, setMapHasMounted] = useState(false);

  const { AuthStore, ShipmentStore } = useRootStore();

  const { refreshToken } = AuthStore;

  const { anonymousKey } = ShipmentStore;

  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    const handleAccessToken = async () => {
      const token = await refreshToken();

      setAccessToken(token);
    };

    if (!anonymousKey) {
      handleAccessToken();
    } else {
      setAccessToken(anonymousKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMountedStatus = (event) => {
    if (event.data === 'map-loaded') {
      setMapHasMounted(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', updateMountedStatus, true);

    return window.removeEventListener('message', updateMountedStatus);
  }, []);

  useEffect(() => {
    const map = document.getElementById('mapFrame');

    if (map && map.contentWindow) {
      map.contentWindow.postMessage(
        {
          type: 'updateDetails',
          payload: {
            address,
            latitude,
            longitude,
            eta: `ETA: ${eta}`,
            travelDistance: `${travelDistance?.toFixed(1)}km away`,
          },
        },
        '*'
      );
    }
  }, [address, eta, latitude, longitude, travelDistance, mapHasMounted]);

  if (!accessToken) return null;

  return (
    <MapWrapper visible={showMap}>
      <small className="lastUpdated">Last updated at: {format(lastUpdatedLocationTime, 'h:mm a')}</small>
      <span className="etaTime">ETA {eta}</span>
      <CloseMapButtonContainer>
        <ShowMapComponent onClick={() => setShowMap(false)} title="Close Map" />
      </CloseMapButtonContainer>
      {showMap && (
        <MapFrame
          id="mapFrame"
          width="100%"
          height="100%"
          title="map"
          src={`${process.env.REACT_APP_API_URL}/map?auth=${accessToken}`}
        />
      )}
    </MapWrapper>
  );
};

const MapFrame = styled.iframe`
  border: none;
`;

const CloseMapButtonContainer = styled.div`
  position: absolute;
  z-index: 20;
  left: 10px;
`;

Map.propTypes = {
  showMap: PropTypes.bool.isRequired,
  setShowMap: PropTypes.func.isRequired,
};

export { Map };
