/* eslint-disable no-console */
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get, omit } from 'lodash';

import { filterParameters } from '../../utils/filter-parameters';

const SHIPMENT_FILTER_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

const ShipmentStore = types
  .model('ShipmentStore', {
    hasFetched: false, // used to control the initial data load
    isFetching: false,
    limit: types.optional(types.number, 25),
    parameters: types.optional(
      types.model('Parameters', {
        day: types.optional(types.string, ''),
        keyword: types.optional(types.string, ''),
        shipmentStatus: types.optional(types.string, ''),
        dateFrom: types.optional(types.string, ''),
        dateTo: types.optional(types.string, ''),
        location: types.optional(types.string, ''),
      }),
      {}
    ),
    start: types.optional(types.number, 0),
    vehicleLocationData: types.optional(
      types.model('LocationData', {
        Latitude: types.optional(types.number, 37.8136), // Melbourne latitude
        Longitude: types.optional(types.number, 144.9631),
      }),
      {}
    ),
  })
  .actions((self) => {
    const { api, logger, setQueryString } = getEnv(self);
    const { ToastStore } = getRoot(self);

    return {
      loadShipments: flow(function* (overrides = {}) {
        self.setQueryString(overrides);

        self.isFetching = true;

        const { dateFrom, dateTo, keyword = null, ...queryParameters } = omit(self.activeParameters, ['day']);

        const params = new URLSearchParams(
          keyword
            ? { keyword, limit: self.limit }
            : {
                ...queryParameters,
                plannedDeliveryDateTimeFrom: self.formatDateFrom(parseISO(dateFrom)),
                plannedDeliveryDateTimeTo: self.formatDateTo(parseISO(dateTo)),
                limit: self.limit,
              }
        );

        const response = yield api.get(`shipments?${params.toString()}`);

        if (response.status !== 200) {
          return [];
        }

        self.hasFetched = true;
        self.isFetching = false;

        const {
          data: { pagination, shipments },
        } = response;

        return { pagination, shipments };
      }),
      loadMore: () => {
        self.setParameters({ start: (self.start += self.limit) });
      },
      setStart: (start) => {
        self.start = start;
      },
      setParameters: (parameters) => {
        const newParameters = { ...self.parameters, ...parameters };

        const { dateFrom, dateTo } = newParameters;

        let { day } = newParameters;

        if (!day && !dateFrom && !dateTo) {
          day = 'Today';
        }

        self.parameters = { ...newParameters, day };
      },
      setQueryString: (overrides = {}) => {
        setQueryString({ ...self.queryParameters, ...overrides });
      },
      clearFilters: () => {
        self.parameters = {
          ...self.parameters,
          location: '',
          shipmentStatus: '',
          dateFrom: '',
          dateTo: '',
          day: 'Today',
        };
      },
      loadShipmentDetail: flow(function* (shipmentId) {
        if (!shipmentId) {
          return { data: null };
        }

        const params = new URLSearchParams(window.location.search);
        const response = yield api.get(`shipments/${shipmentId}?${params.toString()}`, {
          acquireSilently: true,
          restricted: false,
        });

        if (response.status === 401) {
          return {
            error: 'Sorry, this link has expired.',
          };
        }

        const data = get(response, 'data.shipment');

        return { data };
      }),

      loadShipmentLocation: flow(function* (shipmentId) {
        if (!shipmentId) {
          return {
            error: 'There is no Shipment ID available.',
          };
        }

        const params = new URLSearchParams(self.anonymousKey ? { key: self.anonymousKey } : {});

        const response = yield api.get(`vehicleLocation/${shipmentId}?${params.toString()}`, {
          acquireSilently: true,
          restricted: false,
        });

        if (response.status === 401) {
          return {
            error: 'Sorry, this link has expired.',
          };
        }

        if (response.status === 500) {
          return {
            error: 'The Vehicle location service is down. Please try again later.',
          };
        }

        const data = get(response, 'data.shipment');

        if (!data) {
          return {
            error: 'There is no location data available for this shipment.',
          };
        }

        const { Latitude, Longitude, deliveryLocation, travelDurationTraffic, travelDistance } = data;

        if (!Latitude || !Longitude) {
          console.log('latitude and longitude do not exist');
          return {
            error: 'latitude and longitude do not exist',
          };
        }

        return { Latitude, Longitude, deliveryLocation, travelDurationTraffic, travelDistance };
      }),

      getShipmentPod: flow(function* (podId) {
        try {
          const {
            data: { downloadURL },
            status,
          } = yield api.get(`shipments/${podId}/pod`);

          if (status === 500) {
            throw new Error('Proof of delivery document is not available for this shipment.');
          }

          return downloadURL;
        } catch (error) {
          logger.error('Error downloading POD.', { podId });

          ToastStore.addToast({
            variant: 'error',
            description: 'Proof of delivery document is not available for this shipment.',
          });

          return null;
        }
      }),

      getShipmentPop: flow(function* (popId) {
        try {
          const {
            data: { downloadURL },
            status,
          } = yield api.get(`shipments/${popId}/pop`);

          if (status === 500) {
            throw new Error('Proof of pick up document is not available for this shipment.');
          }

          return downloadURL;
        } catch (error) {
          logger.error('Error downloading POp.', { popId });

          ToastStore.addToast({
            variant: 'error',
            description: 'Proof of pick up document is not available for this shipment.',
          });

          return null;
        }
      }),
    };
  })
  .views((self) => ({
    formatDateFrom: (date) => {
      const dateFrom = startOfDay(date);

      return format(dateFrom, SHIPMENT_FILTER_DATE_FORMAT);
    },
    formatDateTo: (date) => {
      const dateFrom = endOfDay(date);

      return format(dateFrom, SHIPMENT_FILTER_DATE_FORMAT);
    },
    get activeParameters() {
      if (self.parameters.keyword) {
        return { keyword: self.parameters.keyword, limit: self.limit, start: self.start };
      }

      const searchParams = new URLSearchParams(window.location.search);

      const params = filterParameters(self.parameters);

      if (params.day === 'Today') {
        const TODAY_DATE = new Date();

        params.dateFrom = self.formatDateFrom(TODAY_DATE);
        params.dateTo = self.formatDateTo(TODAY_DATE);
      }

      if (params.day === 'Tomorrow') {
        const TOMORROW_DATE = new Date();
        TOMORROW_DATE.setDate(new Date().getDate() + 1);

        params.dateFrom = self.formatDateFrom(TOMORROW_DATE);
        params.dateTo = self.formatDateTo(TOMORROW_DATE);
      }

      // if we still have no dates set, set to today
      params.dateFrom = params.dateFrom || searchParams.get('dateFrom');
      params.dateTo = params.dateTo || searchParams.get('dateTo');

      params.start = self.start;

      return params;
    },
    get anonymousKey() {
      const params = new URLSearchParams(window.location.search);

      return params.get('key') || null;
    },
    get keyword() {
      return self.parameters.keyword;
    },
    get queryParameters() {
      // Not all parameters should appear in the URL.
      const { keyword, shipmentStatus, location, dateTo, dateFrom, day } = self.parameters;

      if (keyword) {
        return filterParameters({
          keyword,
        });
      }

      return filterParameters({
        shipmentStatus,
        location,
        day: day === 'Tomorrow' && day,
        dateTo: !day && dateTo,
        dateFrom: !day && dateFrom,
      });
    },
    get isFiltersActive() {
      const { shipmentStatus, location, dateFrom, dateTo, day } = self.parameters;

      const parametersHaveValue = !!shipmentStatus || !!location;

      const dateNotDefault = !!dateFrom && !!dateTo && day !== 'Today';

      return parametersHaveValue || dateNotDefault;
    },
    get dateDefaultValue() {
      const { day, dateFrom, dateTo } = self.activeParameters;

      if (day === 'Today') {
        const TODAY_DATE = new Date();

        return { from: TODAY_DATE, to: TODAY_DATE };
      }

      if (day === 'Tomorrow') {
        const TOMORROW_DATE = new Date();
        TOMORROW_DATE.setDate(new Date().getDate() + 1);

        return { from: TOMORROW_DATE, to: TOMORROW_DATE };
      }

      return {
        from: new Date(dateFrom),
        to: new Date(dateTo),
      };
    },
    get dateFilterLabel() {
      const { dateFrom, dateTo, day } = self.activeParameters;

      if (day) return day;

      if (!dateFrom && !dateTo) return null;

      const formattedStart = format(new Date(dateFrom), 'd MMM');
      const formattedEnd = format(new Date(dateTo || dateFrom), 'd MMM');

      if (formattedStart === formattedEnd) {
        return formattedStart;
      }

      return `${formattedStart} → ${formattedEnd}`;
    },
  }));

export { ShipmentStore };
