import { invokeMap } from 'lodash';
import Rollbar from 'rollbar';

class Logger {
  constructor({ accessToken, environment = 'development' }) {
    const client = new Rollbar({
      accessToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: environment !== 'development',
      payload: {
        environment,
      },
    });

    this.outputs = [console, client];
  }

  error(...args) {
    invokeMap(this.outputs, 'error', ...args);
  }

  info(...args) {
    invokeMap(this.outputs, 'info', ...args);
  }
}

const logger = new Logger({
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENV || 'development',
});

export { Logger, logger };
