import { Controller } from 'react-hook-form';
import { createPortal } from 'react-dom';
import { omit } from 'lodash';
import styled from 'styled-components';

import { BookingFormRow } from '../booking-form-row';
import { Button, Flex, form, P } from '../../ui-library';
import { CargoItemSummary } from './cargo-item-summary';
import { CargoTable } from '../../cargo-table';
import { DeleteItemModal } from '../../delete-item-modal';
import { ItemForm } from './item-form';
import { ItemModal } from '../../item-modal';
import { SelectAllButton } from '../../select-all-button';
import { useHydrateFormValue } from '../use-hydrate-form-value';
import { useItemSelect } from '../../../hooks/use-item-select';
import { useModal } from '../../../hooks/use-modal';
import { useModalState } from '../../modal-context';
import { useRootStore } from '../../store-provider/lib';
import { useTemplateState } from '../../template-context';

const { ErrorLabel, Form, useFormState } = form;

const CargoListing = () => {
  const field = 'items';

  const { ToastStore } = useRootStore();
  const { control, getError } = useFormState();
  const { modalContainer } = useModalState();

  const { closeModal, isModalOpen, openModal } = useModal();

  useHydrateFormValue(field, []);

  const { normalisedFields } = useTemplateState();

  const {
    activeItem,
    clearSelection,
    handleAddItem,
    handleRemoveItems,
    handleSubmit,
    isAllSelected,
    selectedItems,
    setActiveItemIndex,
    toggleAll,
    toggleItem,
  } = useItemSelect(field);

  const validation = {
    validate: {
      hasMultipleItems: (value) => {
        return (value && value.length > 0) || 'A booking must have at least one cargo item.';
      },
    },
  };

  const error = getError(field);

  return (
    <Controller
      control={control}
      name="items"
      rules={validation}
      render={({ field: { value = [], onBlur, onChange } }) => {
        const hasItemsSelected = selectedItems.length > 0;

        const duplicateItems = () => {
          const newValues = handleAddItem({
            [field]: selectedItems.map((index) => {
              const item = value[index];

              return omit(item, 'bookingItemID');
            }),
          });

          onChange(newValues);
        };

        return (
          <>
            <BookingFormRow>
              <CargoListingStatus alignItems="center" color="greySeven" mb={3}>
                {hasItemsSelected ? <P>{selectedItems.length} items selected</P> : <CargoItemSummary />}
              </CargoListingStatus>
              {hasItemsSelected ? (
                <Flex>
                  <Button
                    variant="tertiary"
                    minWidth="140px"
                    mr={2}
                    onClick={() => {
                      duplicateItems();
                      clearSelection();
                      onBlur();
                    }}
                  >
                    Duplicate
                  </Button>
                  <DeleteItemModal
                    message={`Delete ${selectedItems.length} cargo ${selectedItems.length === 1 ? 'item' : 'items'}?`}
                    onDeleteItem={() => {
                      onChange(handleRemoveItems());

                      ToastStore.addToast({
                        variant: 'success',
                        description: `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} deleted`,
                      });

                      clearSelection();
                      onBlur();
                    }}
                  />
                </Flex>
              ) : (
                <Button
                  minWidth="220px"
                  variant="secondary"
                  onClick={() => {
                    setActiveItemIndex(null);
                    openModal();
                  }}
                >
                  Add Cargo
                </Button>
              )}
            </BookingFormRow>
            {error && (
              <BookingFormRow mb={0}>
                <ErrorLabel>{error}</ErrorLabel>
              </BookingFormRow>
            )}
            {value.length > 0 && (
              <CargoTable
                items={value}
                normalisedFields={normalisedFields}
                onClick={(index) => {
                  setActiveItemIndex(index);
                  openModal();
                }}
                SelectAllButton={
                  <SelectAllButton
                    onClick={toggleAll}
                    hasItemsSelected={hasItemsSelected}
                    isAllSelected={isAllSelected}
                  />
                }
                selectedItems={selectedItems}
                toggleItem={toggleItem}
              />
            )}
            {!!modalContainer &&
              createPortal(
                <Form
                  name="cargoListingForm"
                  onSubmit={(data) => {
                    onChange(handleSubmit(data));
                    onBlur();
                    closeModal();
                  }}
                >
                  <ItemModal
                    buttonText={activeItem === null ? 'Add Item' : 'Save Item'}
                    title={activeItem === null ? 'Add Cargo Item' : 'Edit Cargo Item'}
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                  >
                    <ItemForm activeItem={activeItem} />
                  </ItemModal>
                </Form>,
                modalContainer
              )}
          </>
        );
      }}
    />
  );
};

const CargoListingStatus = styled(Flex)`
  ${P} {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

export { CargoListing };
