import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Modal } from '../ui-library';

const NumbersModal = ({ referenceNumbers, isModalOpen, closeModal, title }) => (
  <Modal title={title} size="lg" isOpen={isModalOpen} isScrollable closeModal={closeModal}>
    <Wrapper>
      {referenceNumbers.map((number) => (
        <div key={number}>{number}</div>
      ))}
    </Wrapper>
  </Modal>
);

const Wrapper = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 2rem;
`;

NumbersModal.propTypes = {
  title: PropTypes.string.isRequired,
  referenceNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export { NumbersModal };
