import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, formatDateEta, MobileCard, ShowOn, StatusLabel, Table } from '../../ui-library';
import { deliveryStatuses } from '../../../constants/statuses';
import { ExpandableList } from '../../expandable-list';
import { getIsFutile } from '../../../utils/get-is-futile';
import { getStatus } from '../../../utils/get-status';
import { PodDownloadLink } from '../../pod-download-link';
import { PopDownloadLink } from '../../pop-download-link';
import { renderReferenceNumbers } from '../../../utils/render-reference-numbers';
import { renderValueNumber } from '../../../utils/render-value-number';
import { shipmentPropType } from '../../../prop-types';
import { StyledDownloadIcon } from '../../shipment-milestones/styles';
import { useRootStore } from '../../store-provider/lib';

const { TableRow, TableCell } = Table;

const ShipmentRow = ({ data, openNumbersModal }) => {
  const { ShipmentStore } = useRootStore();

  const { keyword } = ShipmentStore;

  const {
    shipmentId,
    customerReference,
    pickupLocation,
    plannedPickupDateTimeFrom,
    plannedPickupDateTimeTo,
    actualPickupDateTime,
    deliveryLocation,
    plannedDeliveryDateTimeFrom,
    plannedDeliveryDateTimeTo,
    actualDeliveryDateTime,
    shipmentStatus,
    futileReference,
    purchaseOrder,
  } = data;

  const pickupLocationString = pickupLocation ? `${pickupLocation.suburb}, ${pickupLocation.state}` : '';
  const deliveryLocationString = deliveryLocation ? `${deliveryLocation.suburb}, ${deliveryLocation.state}` : '';
  const pickupEta = formatDateEta(plannedPickupDateTimeFrom, plannedPickupDateTimeTo, actualPickupDateTime);
  const deliveryEta = formatDateEta(plannedDeliveryDateTimeFrom, plannedDeliveryDateTimeTo, actualDeliveryDateTime);
  const shipmentIsFutile = getIsFutile(futileReference);
  const shipmentIsDelivered = shipmentStatus === 'Delivered' && !shipmentIsFutile;
  const shipmentIsInTransit = shipmentStatus === 'In Transit' || shipmentStatus === 'In Transit - Last Mile';
  const customerReferenceList = customerReference ? customerReference.split(',') : [];
  const purchaseOrderList = purchaseOrder ? purchaseOrder.split(',') : [];
  // if a keyword is present in purchaseOrderList, move it to the first position
  if (purchaseOrderList.length > 0 && keyword) {
    const keywordIndex = purchaseOrderList.indexOf(keyword);

    if (keywordIndex > -1) {
      purchaseOrderList.splice(keywordIndex, 1);
      purchaseOrderList.unshift(keyword);
    }
  }

  const referenceNumbersOnClick = () => openNumbersModal(customerReferenceList, 'Customer reference numbers');
  const purchaseOrderOnClick = () => openNumbersModal(purchaseOrderList, 'Purchase order numbers');

  const deliveryStatus = getStatus(deliveryStatuses, shipmentIsFutile ? 'Futile' : shipmentStatus);

  return (
    <>
      <ShowOn screenSize="mobile">
        <MobileCard
          as={Link}
          to={`/track/${shipmentId}`}
          cardId={shipmentId}
          backgroundColor={deliveryStatus.backgroundColor}
          status={shipmentIsFutile ? 'Futile' : shipmentStatus}
        >
          <TableRow>
            <TableCell>Reference #</TableCell>
            <TableCell>{renderValueNumber(customerReference)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Purchase Order #</TableCell>
            <TableCell>{purchaseOrderList ? <ExpandableList array={purchaseOrderList} /> : null}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pickup</TableCell>
            <TableCell>
              <div>{pickupLocationString}</div>
              {pickupEta}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Delivery</TableCell>
            <TableCell>
              <div>{deliveryLocationString}</div>
              {deliveryEta}
            </TableCell>
          </TableRow>
        </MobileCard>
      </ShowOn>
      <ShowOn screenSize="desktop" position="relative">
        <TableRow as={Link} to={`/track/${shipmentId}`}>
          <TableCell>{shipmentId}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            {renderReferenceNumbers(customerReferenceList, 'N/A', shipmentId, referenceNumbersOnClick)}
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            {renderReferenceNumbers(purchaseOrderList, 'N/A', shipmentId, purchaseOrderOnClick)}
          </TableCell>
          <TableCell>
            <Box mb="4px" fontWeight={600}>
              {pickupLocationString}
            </Box>
            {pickupEta}
          </TableCell>
          <TableCell>
            <Box mb="4px" fontWeight={600}>
              {deliveryLocationString}
            </Box>
            {deliveryEta}
          </TableCell>
          <TableCell>
            <StatusLabel type="delivery" variant={deliveryStatus.variant}>
              {deliveryStatus.label}
            </StatusLabel>
          </TableCell>
          <TableCell>
            {shipmentIsDelivered && (
              <PodDownloadLink podID={shipmentId} variant="list">
                <StyledDownloadIcon />
                POD
              </PodDownloadLink>
            )}
            {(shipmentIsInTransit || shipmentIsDelivered || shipmentIsFutile) && (
              <PopDownloadLink popID={shipmentId} variant="list">
                <StyledDownloadIcon />
                POP
              </PopDownloadLink>
            )}
          </TableCell>
        </TableRow>
      </ShowOn>
    </>
  );
};

ShipmentRow.propTypes = {
  data: shipmentPropType.isRequired,
  openNumbersModal: PropTypes.func.isRequired,
};

export { ShipmentRow };
