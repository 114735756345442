import PropTypes from 'prop-types';

import { Box, Flex, form } from '../../../ui-library';
import { DynamicFormInput } from '../../../dynamic-form-input';
import { LabelledInput } from '../../../labelled-input';

const { SubmitButton, useFormState } = form;

const fieldOptions = {
  packingEquipment: {
    select: 'packingEquipment',
    input: 'notePackingEquipment',
  },
  specialisedHandling: {
    select: 'specialisedHandling',
    input: 'noteSpecialHandling',
  },
};

const SpecialEquipmentForm = ({ specialEquipmentType }) => {
  const { isValid } = useFormState();

  const { select, input } = fieldOptions[specialEquipmentType] || {};

  if (!select || !input) return null;

  return (
    <Flex
      flexDirection="column"
      flex={1}
      key={specialEquipmentType} /* trigger render when changing special equipment type */
    >
      <Box mb={3}>
        <DynamicFormInput
          name={select}
          prepopulateField={false}
          render={({ label, name, options, type, ...props }) => (
            <LabelledInput label={label} name={name} type={type} options={options} {...props} />
          )}
        />
      </Box>
      <DynamicFormInput
        name={input}
        prepopulateField={false}
        render={({ label, name, validation }) => (
          <Box mb={3}>
            <LabelledInput
              id={name}
              label={label}
              name={name}
              type="textarea"
              validation={{ required: `${label} is required.`, ...validation }}
            />
          </Box>
        )}
      />
      <SubmitButton disabled={!isValid}>Add Equipment</SubmitButton>
    </Flex>
  );
};

SpecialEquipmentForm.propTypes = {
  specialEquipmentType: PropTypes.oneOf(Object.keys(fieldOptions)).isRequired,
};

export { SpecialEquipmentForm };
