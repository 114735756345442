import { includes } from 'lodash';
import PropTypes from 'prop-types';

import { API } from '../../utils/api';
import { Authenticator } from '../../utils/auth';
import { logger } from '../../utils/logger';
import { RootStore, RootStoreProvider } from './lib';
import { setQueryString } from '../../utils/set-query-string';

const branding = includes(window.location.hostname, 'bevchain') ? 'bevChain' : 'linfox';

const auth = new Authenticator({
  logger,
  clientId: process.env.REACT_APP_CLIENT_ID,
  tenantId: process.env.REACT_APP_TENANT_ID,
  redirectUri:
    branding === 'bevChain' ? process.env.REACT_APP_REDIRECT_URI_BEVCHAIN : process.env.REACT_APP_REDIRECT_URI,
});

const api = new API({ apiUrl: process.env.REACT_APP_API_URL, auth, logger });

const StoreProvider = ({ children, initialState, dependencies }) => {
  const Store = RootStore.create(initialState, { api, auth, logger, setQueryString, ...dependencies });

  return <RootStoreProvider value={Store}>{children}</RootStoreProvider>;
};

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({}),
  dependencies: PropTypes.shape({}),
};

StoreProvider.defaultProps = {
  initialState: {},
  dependencies: {},
};

export { StoreProvider };
