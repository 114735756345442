import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const Link = ({ disabled, to, ...props }) => {
  if (disabled) return <div data-testid={`link-${to}`} {...props} />;

  return <RouterLink to={to} data-testid={`link-${to}`} {...props} />;
};

Link.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  disabled: false,
};

export { Link };
