import { PropTypes } from 'prop-types';
import Clamp from 'react-multiline-clamp';
import styled from 'styled-components';

import { mobileOnly } from '../../../utils/responsive-styles';

// By default, DetailsListItem will hide the entire row if the child value is empty.
// If we want to display the row regardless, use `showEmpty` to force the row to display.
const DetailsListItem = ({ title, children, showEmpty, clampLines, ...props }) => {
  if (!showEmpty && !children) {
    return null;
  }

  return (
    <List {...props}>
      <Title>{title}</Title>
      <Description>
        {clampLines > 0 ? (
          <Clamp lines={clampLines} maxLines={100} withToggle texts={{ showMore: 'See more', showLess: 'See less' }}>
            {children}
          </Clamp>
        ) : (
          children
        )}
      </Description>
    </List>
  );
};

const List = styled.dl`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.space.sm};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 1.4;
  margin: 0 -${({ theme }) => theme.space.sm};
  position: relative;

  ${mobileOnly} {
    padding: ${({ theme }) => theme.space.xs};
    margin: 0;
  }
  span + button {
    color: ${({ theme }) => theme.colors.interactionSeven};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => theme.space.xs};
    margin: 0;
    flex-direction: row;
  }

  :last-child {
    margin-bottom: ${({ theme }) => theme.space.md};
  }

  :nth-child(2n) {
    background-color: #fcfcfc;
  }
`;

const Title = styled.dt`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: ${({ theme }) => theme.space.sm};
  color: ${({ theme }) => theme.colors.greySeven};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 40%;
  }

  ${mobileOnly} {
    margin-bottom: ${({ theme }) => theme.space.xxs};
  }
`;

const Description = styled.dd`
  margin: 0;
  color: ${({ theme }) => theme.colors.greySix};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc((100% / 4) * 3);
  }
  ${mobileOnly} {
    line-height: 1.7;
  }
`;

DetailsListItem.propTypes = {
  showEmpty: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  clampLines: PropTypes.number,
};

DetailsListItem.defaultProps = {
  showEmpty: false,
  clampLines: 0, // If 0 then no line clamping will be implemented (aka, unlimited lines)
};

export { DetailsListItem };
