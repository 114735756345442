import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { attachmentPropType } from '../../../prop-types';
import { colors } from '../../../constants';
import { Download, LoadingIndicator } from '../../icons';
import { TableCell, TableRow } from '../../table';
import { useMounted } from '../../../hooks/use-mounted';

const AttachmentRow = ({ handleAttachmentDownload, attachment }) => {
  const mounted = useMounted();
  const [isLoading, setIsLoading] = useState(false);
  const { createdBy, createdOn, documentID, name } = attachment;

  const onClick = async () => {
    setIsLoading(true);
    await handleAttachmentDownload(attachment);

    if (mounted) {
      setIsLoading(false);
    }
  };

  return (
    <StyledTableRow data-testid={documentID}>
      <TableCell>{name}</TableCell>
      <StyledCell>{createdBy}</StyledCell>
      <StyledCell>{createdOn && format(parseISO(createdOn), 'eee, dd MMM')}</StyledCell>
      <DownloadButton onClick={onClick} type="button">
        {isLoading ? <LoadingIndicator color={colors.interactionSix} /> : <DownloadIcon />}
      </DownloadButton>
    </StyledTableRow>
  );
};

const StyledTableRow = styled(TableRow)`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.borderColorOne};
  border-bottom: none;
  grid-template-columns: 4fr minmax(100px, 2fr) minmax(100px, 1fr) max-content;

  :nth-of-type(even) {
    background-color: ${({ theme }) => theme.global.backgroundColor};
  }

  :last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorOne};
  }
`;

const StyledCell = styled(TableCell)`
  text-align: right;
`;

const DownloadButton = styled.button`
  padding-left: 1rem;
`;

const DownloadIcon = styled(Download)`
  fill: ${({ theme }) => theme.colors.interactionSix};
`;

AttachmentRow.propTypes = {
  attachment: attachmentPropType.isRequired,
  handleAttachmentDownload: PropTypes.func.isRequired,
};

export { AttachmentRow };
