import styled from 'styled-components';

import { Box, DetailsList } from '../../../components/ui-library';

const { DetailsListItem } = DetailsList;

const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.greyOne};
  padding: ${({ theme }) => theme.space.xs} ${({ theme }) => theme.space.sm};
`;

const Resolution = styled(Box)`
  background-color: ${({ theme }) => theme.colors.successOne};
  border: 1px solid ${({ theme }) => theme.colors.successNine};
  border-radius: 4px;
  font-size: 14px;
  padding: ${({ theme }) => theme.space.xs} ${({ theme }) => theme.space.sm};
  position: relative;
`;

const StyledDetailsListItem = styled(DetailsListItem)`
  align-items: center;
`;

const Divider = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyThree};
  &:last-of-type:before {
    border: 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  margin: ${({ theme }) => theme.space.xs} ${({ theme }) => theme.space.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: ${({ theme }) => theme.space.sm};
  }
`;

export { Wrapper, Resolution, StyledDetailsListItem, Divider };
