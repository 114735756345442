import { format, isSameDay, isSameYear, parseISO } from 'date-fns';

const formatDate = (date, dateFormat = '') => {
  const timestamp = Date.parse(date);
  const dateNow = new Date();

  if (!Number.isNaN(timestamp)) {
    const sameYear = isSameYear(dateNow, parseISO(date));
    const defaultDateFormat = sameYear ? 'd MMM, h:mm a' : 'd MMM yyyy, h:mm a';
    return format(parseISO(date), dateFormat || defaultDateFormat);
  }

  if (!date) {
    return '';
  }

  return 'Invalid date';
};

const formatDateEta = (from, to, actual) => {
  if (actual) {
    return formatDate(actual);
  }
  if (from && to) {
    const sameDay = isSameDay(parseISO(from), parseISO(to));
    const fromDateTime = formatDate(from);
    const toDateTime = sameDay ? formatDate(to, 'h:mm a') : formatDate(to);
    return `${fromDateTime} - ${toDateTime}`;
  }

  return '';
};

export { formatDate, formatDateEta };
