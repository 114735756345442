import { deliveryStatuses } from '../../constants/statuses';

export const getIsInLastMile = ({ enableLastMile, shipmentDetail }) => {
  const finishedStatuses = [
    deliveryStatuses.delivered.key,
    deliveryStatuses.cancelled.key,
    deliveryStatuses.futile.key,
  ];

  if (enableLastMile && shipmentDetail) {
    const isShipmentInProgress = !finishedStatuses.includes(shipmentDetail?.shipmentStatus);
    return isShipmentInProgress && shipmentDetail.shipmentStatus === deliveryStatuses.lastMile.key;
  }

  return false;
};
