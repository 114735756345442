import { get as _get } from 'lodash';
import axios from 'axios';

const TOKEN_EXPIRED = 'Access token has expired.';

const FAILED = 'FAILED';
const SUCCEEDED = 'SUCCEEDED';
class API {
  constructor({ auth, apiUrl, logger }) {
    this.apiUrl = apiUrl;
    this.auth = auth;
    this.logger = logger;
  }

  async get(
    path,
    { acquireSilently = false, headers = {}, options = {}, restricted = true, getPath = 'data.message' } = {}
  ) {
    const { apiUrl, auth, logger } = this;

    try {
      let authHeaders = {};

      if (acquireSilently) {
        // Do not prompt a user to enter their credentials to access this endpoint.
        // Used for initial page load, and anonymous track-and-trace, where we need to cater for unauthenticated users.
        const accessToken = await auth.login();

        if (accessToken) {
          authHeaders = {
            'x-api-key': accessToken,
          };
        } else if (restricted) {
          // If this endpoint must be authenticated, and no accessToken is provided, return early.
          return null;
        }
      } else {
        const accessToken = await auth.refreshToken();

        authHeaders = {
          'x-api-key': accessToken,
        };
      }

      const res = await axios.get(`${apiUrl}/${path}`, {
        headers: {
          Accept: 'application/json',
          ...authHeaders,
          ...headers,
        },
        ...options,
      });

      const data = _get(res, getPath);

      return { status: res.status, data };
    } catch (e) {
      if (e.response) {
        const { message = null } = e.response.data;

        // This flow will occur if the lambda was not able to validate the access token provided.
        if (message === TOKEN_EXPIRED) {
          throw new Error(`${message} Please log in again to resume your session.`);
        }
      }

      // This error will occur if AAD is not able to refresh the access token.
      if (e.name && e.name === 'InteractionRequiredAuthError') {
        throw new Error(e.toString());
      }

      const errorObject = {
        status: (e.response && e.response.status) || e.code,
        data: (e.response && e.response.data) || e.errno,
      };

      logger.error('Error occurred during get request', e);

      return errorObject;
    }
  }

  async post(path, { body = {} } = {}) {
    const { apiUrl, auth, logger } = this;

    try {
      const accessToken = await auth.refreshToken();

      const res = await axios.post(`${apiUrl}/${path}`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': accessToken,
        },
      });
      return { status: res.status, data: res.data };
    } catch (e) {
      const errorObject = {
        status: (e.response && e.response.status) || e.code,
        data: (e.response && e.response.data) || e.errno,
      };

      logger.error('Error occurred during post request', e);

      return errorObject;
    }
  }

  async del(path) {
    const { apiUrl, auth, logger } = this;

    try {
      const accessToken = await auth.refreshToken();

      const res = await axios.delete(`${apiUrl}/${path}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': accessToken,
        },
      });
      return { status: res.status, data: res.data };
    } catch (e) {
      const errorObject = {
        status: (e.response && e.response.status) || e.code,
        data: (e.response && e.response.data) || e.errno,
      };

      logger.error('Error occurred during delete request', e);

      return errorObject;
    }
  }

  async stateMachine(path, { body = {}, method = 'POST' } = {}, delay = 2000) {
    const { logger } = this;

    const fetchExecutionArn = async () => {
      if (method === 'POST') {
        return this.post(path, { body });
      }

      return this.get(path, { getPath: 'data' });
    };

    try {
      const response = await fetchExecutionArn();

      const {
        result: { executionArn },
      } = response.data;

      const describeExecution = async (attempts = 0) => {
        // Perform the exponential backoff here, to allow the first invocation to occur
        // after a minimal delay. As the describe call can be quite expensive (as it is authenticated)
        // we ideally want to call it first after a short delay to allow the state machine to complete.
        // (Likewise, exponential backoff means that each invocation is more likely to return in a completed state.)
        await new Promise((resolve) => setTimeout(resolve, 500 + delay * attempts));

        const {
          data: { status, output },
        } = await this.post('describe', { body: { executionArn } });

        if (status === SUCCEEDED) {
          const data = JSON.parse(output);

          return data;
        }

        if (status === FAILED) {
          throw new Error(`Execution ${executionArn} failed.`);
        }

        return describeExecution(attempts + 1);
      };

      return describeExecution();
    } catch (e) {
      const errorObject = {
        status: (e.response && e.response.status) || e.code,
        data: (e.response && e.response.data) || e.errno,
      };

      logger.error('Error occurred during state machine request', e);

      throw errorObject;
    }
  }
}

export { API };
