import { UserAgentApplication } from 'msal';

const LOGIN_REQUEST = {
  scopes: ['user.read'],
  prompt: 'select_account',
};

const ACCESS_TOKEN_REQUEST = {
  scopes: [
    'user.read',
    'user.read.all',
    'user.readbasic.all',
    'directory.read.all',
    'Group.ReadWrite.All',
    'User.Invite.All',
  ],
};

const config = ({ clientId, redirectUri, tenantId }) => ({
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}`,
    clientId,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
});

class Authenticator {
  constructor({ logger, clientId, redirectUri, tenantId }) {
    this.logger = logger;

    this.msalApp = new UserAgentApplication(config({ clientId, redirectUri, tenantId }));
  }

  async logout() {
    this.msalApp.logout();
  }

  async login() {
    const { logger, msalApp } = this;

    try {
      const { accessToken } = await msalApp.acquireTokenSilent(ACCESS_TOKEN_REQUEST);

      return accessToken;
    } catch (acquireTokenSilentError) {
      logger.error({ error: acquireTokenSilentError, message: 'Error running acquireTokenSilent.' });

      return false;
    }
  }

  async refreshToken() {
    const { logger, msalApp } = this;

    try {
      const { accessToken } = await msalApp.acquireTokenSilent(ACCESS_TOKEN_REQUEST);
      return accessToken;
    } catch (acquireTokenSilentError) {
      logger.error({ error: acquireTokenSilentError, message: 'Error running acquireTokenSilent.' });

      try {
        const { accessToken } = await msalApp.acquireTokenPopup(ACCESS_TOKEN_REQUEST);
        return accessToken;
      } catch (acquireTokenPopupError) {
        logger.error({ error: acquireTokenPopupError, message: 'Error running acquireTokenPopupError.' });

        await msalApp.loginPopup(LOGIN_REQUEST);

        return this.refreshToken();
      }
    }
  }
}

export { Authenticator, config };
