import { flatten, get, includes, map, uniq } from 'lodash';

import { contractPrivileges } from '../../components/ui-library/constants';
import { useRootStore } from '../../components/store-provider/lib';

const useHasPrivilege = (privilegeName) => {
  const { AuthStore } = useRootStore();
  const { contracts, isFetching } = AuthStore;

  if (!privilegeName) {
    return true;
  }

  if (!contracts || isFetching) {
    return false;
  }

  const userContractPrivileges = flatten(map(contracts, ({ privilege }) => privilege));

  const privileges = uniq(map(userContractPrivileges, (contractPrivilege) => get(contractPrivilege, 'name')));

  if (includes(privileges, contractPrivileges.trackAgent)) {
    privileges.push(contractPrivileges.track);
  }

  return includes(privileges, privilegeName);
};

export { useHasPrivilege };
