// TODO: refactor to share TextField component (currently almost identical)

import { isNil, trim } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import autosize from 'autosize';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { backgroundStyle, borderStyle, focusStyle } from '../styles';
import { Box } from '../../../grid';
import { ErrorLabel } from '../error-label';
import { useFormState } from '../..';

const TextArea = ({ maxHeight, name, validation, ...props }) => {
  const [hasFocus, setFocus] = useState();
  const [hasHover, setHover] = useState();

  const textNode = useRef();

  const { getError, register, isSubmitted, setValue } = useFormState();

  const {
    onBlur,
    onChange,
    ref: registerRef,
  } = register(name, {
    ...validation,
    onBlur: (event) => {
      setValue(name, trim(event.target.value));
      setFocus(false);
    },
  });

  const mount = useCallback(
    (node) => {
      autosize(node);
      registerRef(node);
      textNode.current = node;
    },
    [registerRef]
  );

  useEffect(() => {
    if (textNode.current) {
      autosize.update(textNode.current);
    }
  }, [isSubmitted]);

  const error = getError(name);

  return (
    <Box position="relative">
      <StyledTextArea
        hasError={!isNil(error)}
        hasFocus={hasFocus}
        hasHover={hasHover}
        maxHeight={maxHeight}
        onFocus={() => setFocus(true)}
        onBlur={onBlur}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        name={name}
        {...props}
        onChange={onChange}
        ref={mount}
        type="textarea"
      />
      {error && (
        <Box position="absolute">
          <ErrorLabel data-testid={`error-${name}`}>{error}</ErrorLabel>
        </Box>
      )}
    </Box>
  );
};

const StyledTextArea = styled.textarea`
  ${backgroundStyle};
  ${borderStyle};
  ${focusStyle};

  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.base};
  padding: ${({ theme }) => theme.space.xs};
  resize: vertical;
  width: 100%;
  overflow: scroll;
  max-height: ${({ maxHeight }) => maxHeight};

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border-color: ${theme.colors.dangerSeven};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyFour};
    opacity: 1;
  }
`;

TextArea.propTypes = {
  maxHeight: PropTypes.string,
  name: PropTypes.string.isRequired,
  validation: PropTypes.shape(),
};

TextArea.defaultProps = {
  maxHeight: '40vh',
  validation: null,
};

export { TextArea };
