import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useFetchLocation } from '../../../hooks/use-fetch-location';

const MapContext = createContext();

const { Provider } = MapContext;

const MapContextProvider = ({ children, deliveryLocation, shipmentId }) => {
  const address = `${deliveryLocation.street} ${deliveryLocation.suburb} ${deliveryLocation.postcode} ${deliveryLocation.state}`;

  const shipmentLocation = useFetchLocation(shipmentId);

  const {
    eta,
    isFetching,
    lastUpdatedLocationTime,
    Latitude: latitude,
    Longitude: longitude,
    travelDistance,
  } = shipmentLocation;

  const value = {
    eta,
    address,
    isFetching,
    lastUpdatedLocationTime,
    latitude,
    longitude,
    travelDistance,
  };

  if (isFetching) return null;

  return <Provider value={value}>{children}</Provider>;
};

MapContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  deliveryLocation: PropTypes.shape({
    name: PropTypes.string,
    street: PropTypes.string,
    suburb: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.string,
  }).isRequired,
  shipmentId: PropTypes.string.isRequired,
};

const useMapState = () => {
  const context = useContext(MapContext);

  if (context === undefined) {
    throw new Error('Component must be used within a MapContextProvider.');
  }

  return context || {};
};

const ConditionalMapContextProvider = ({ children, isInLastMile, ...props }) => {
  if (!isInLastMile) return children;

  return <MapContextProvider {...props}>{children}</MapContextProvider>;
};

ConditionalMapContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isInLastMile: PropTypes.bool.isRequired,
};

export { ConditionalMapContextProvider as MapContextProvider, useMapState };
