import styled from 'styled-components';

import { Input } from '../../form/input';
import { useFormState } from '../../form';

const MessageInput = () => {
  const { watch } = useFormState();
  const { message } = watch();

  return (
    <StyledInput
      aria-label="comment"
      id="description"
      type="textarea"
      // @TODO: Update to name="comment"
      name="message"
      placeholder="Type your reply here"
      rows="1"
      validation={{
        required: {
          value: /^(?!\s*$).+/,
          message: '',
        },
      }}
      hasValue={!!message}
    />
  );
};

const StyledInput = styled(Input)`
  background-color: ${({ hasValue, theme }) => (hasValue ? theme.colors.white : theme.colors.greyOne)};
  color: ${({ theme }) => theme.colors.greySeven};
  display: flex;
  padding: 12px;
  padding-right: 35px;
`;

export { MessageInput };
