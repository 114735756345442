import { isEmpty, upperCase } from 'lodash';

const EXPECTED_EMPTY_VALUE = 'NUL';

const renderValueNumber = (valueNumber, emptyValueString = '', keyPrefix = '') => {
  const isMissing = isEmpty(valueNumber) || upperCase(valueNumber) === EXPECTED_EMPTY_VALUE;

  if (isMissing) {
    return emptyValueString;
  }

  if (Array.isArray(valueNumber)) {
    return valueNumber.map((value, index) => (
      <div key={`${keyPrefix} ${value}`}>
        {index < 3 && value}
        {index > 3 && '...show more'}
      </div>
    ));
  }

  return valueNumber;
};

export { renderValueNumber };
