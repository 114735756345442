import { grid } from 'styled-system';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { AdditionalReferences } from '../../components/additional-references';
import { Breadcrumbs, constants, FileUpload, Flex, form, H2, P, usePageTitle } from '../../components/ui-library';
import { CaseFormSection } from '../../components/case-form-section';
import { detailFields, skuFields } from '../../constants/case';
import { DynamicFormInput } from '../../components/dynamic-form-input';
import { HeaderBar } from '../../components/header-bar';
import { LabelledInput } from '../../components/labelled-input';
import { ModalContextProvider } from '../../components/modal-context';
import { SKUListing } from '../../components/sku-listing';
import { TemplateContextProvider } from '../../components/template-context';
import { useFetchTemplate } from '../../hooks/use-fetch-template';
import { useRootStore } from '../../components/store-provider/lib';

const { Form, SubmitButton } = form;

const CaseCreate = () => {
  usePageTitle('Create Case');
  const history = useHistory();
  const { CaseStore } = useRootStore();

  const formSections = useRef({});

  const [modalContainer, setModalContainer] = useState();

  const [disabledForm, setDisabledForm] = useState(false);

  const { contractID } = useParams();

  const templateData = useFetchTemplate(contractID);

  const { contractName = null, fields = [] } = templateData || {};

  const isValid = () => {
    const formKeys = Object.keys(formSections.current);

    return formKeys.reduce((acc, cur) => {
      const currentForm = formSections.current[cur];

      currentForm.trigger();

      const valid = cur.isValid;

      // if this is the first error, scroll to view
      if (!valid && acc) {
        const firstError = document.getElementById(cur);

        if (firstError) {
          firstError.scrollIntoView();
        }
      }

      return acc && currentForm.isValid;
    }, true);
  };

  const onSubmit = async () => {
    setDisabledForm(true);
    const valid = isValid();

    if (valid) {
      const allForms = Object.values(formSections.current);

      const values = allForms.reduce((acc, cur) => {
        return { ...acc, ...cur.getValues() };
      }, {});

      // 'Select' fields will default to an empty string if there is no selection, which is required by the select component.
      // However, the Case API expects empty select options to be `null`.
      // Note: this behaviour is currently inconsistent with Online Bookings, so cannot be handled more generally.
      // Create a proxy object to transform the value.

      const caseDetails = new Proxy(values, {
        get(target, prop) {
          const templateField = fields.find(({ apiField }) => apiField === prop);

          if (templateField?.type === 'Select') {
            if (target[prop] === '') return null;
          }

          return Reflect.get(target, prop);
        },
      });

      const { success } = await CaseStore.createCase({ contractID, caseDetails });

      if (success) {
        history.push('/cases');
      }
      setDisabledForm(false);
    }
  };

  const breadCrumbTrail = {
    title: 'Create Case',
    ancestors: [
      {
        title: 'All Cases',
        url: '/cases',
      },
    ],
  };

  const mount = useCallback(
    (id) => (node) => {
      if (node) {
        // eslint-disable-next-line no-param-reassign
        node.id = id;
        formSections.current[id] = node;
      }
    },
    [formSections]
  );

  // normalise the fields object
  const fieldKeys = useMemo(() => fields.reduce((acc, cur) => [...acc, cur.id], []), [fields]);

  // Determine if the detail fields are present in this template.
  const displayDetailFields = detailFields.filter((x) => fieldKeys.includes(x)).length > 0;

  // Determine if SKU fields are present in this template.
  const displaySKUFields = skuFields.filter((x) => fieldKeys.includes(x)).length > 0;

  if (!templateData) return null;

  return (
    <>
      <div ref={setModalContainer} />
      <HeaderBar>
        <Flex
          flex={1}
          maxWidth={constants.globalStyles.pageMaxWidth}
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumbs entry={breadCrumbTrail} margin="0" />
        </Flex>
      </HeaderBar>
      <BackgroundWrapper>
        <ModalContextProvider value={{ modalContainer }}>
          <ContentWrapper disabled={disabledForm}>
            <TemplateContextProvider value={{ templateData }}>
              <>
                <div>
                  <H2>Create Case</H2>
                  <P color="greyFive">{contractName}</P>
                </div>
                <Form ref={mount('overview')} mode="onChange">
                  <CaseFormSection title="Overview">
                    <DynamicFormInput
                      hidden
                      name="keyContact"
                      render={(props) => (
                        <Cell gridColumn="span 3">
                          <LabelledInput {...props} />
                        </Cell>
                      )}
                    />
                    <DynamicFormInput
                      name="caseTitle"
                      render={(props) => (
                        <Cell gridColumn="span 3">
                          <LabelledInput {...props} />
                        </Cell>
                      )}
                    />
                    <DynamicFormInput
                      name="caseType"
                      render={(props) => (
                        <Cell gridColumn="span 2">
                          <LabelledInput {...props} />
                        </Cell>
                      )}
                    />
                    <DynamicFormInput
                      name="casePriority"
                      render={(props) => (
                        <Cell gridColumn="span 1">
                          <LabelledInput {...props} />
                        </Cell>
                      )}
                    />
                    <DynamicFormInput
                      name="description"
                      render={(props) => (
                        <Cell gridColumn="span 6">
                          <LabelledInput {...props} style={{ height: '110px' }} />
                        </Cell>
                      )}
                    />
                    <AdditionalReferences
                      render={({ ...props }) => (
                        <Cell gridColumn="span 3">
                          <LabelledInput {...props} />
                        </Cell>
                      )}
                    />
                  </CaseFormSection>
                </Form>
                {displayDetailFields && (
                  <Form ref={mount('details')} mode="onChange">
                    <CaseFormSection title="Details">
                      {detailFields.map((field) => (
                        <DynamicFormInput
                          key={field.id}
                          name={field}
                          render={(props) => (
                            <Cell gridColumn="span 3">
                              <LabelledInput {...props} />
                            </Cell>
                          )}
                        />
                      ))}
                    </CaseFormSection>
                  </Form>
                )}
                {displaySKUFields && (
                  <Form ref={mount('sku')} mode="onChange">
                    <CaseFormSection title="SKU Details">
                      <SKUListing />
                    </CaseFormSection>
                  </Form>
                )}
              </>
              <Form ref={mount('attachments')} mode="onChange">
                <CaseFormSection title="Attachments">
                  <Wrapper>
                    <FileUpload name="attachments" canUploadMultiple />
                  </Wrapper>
                </CaseFormSection>
              </Form>
            </TemplateContextProvider>
            <ButtonWrapper>
              <Form onSubmit={onSubmit}>
                <SubmitButton gridColumn="-1" size="medium">
                  Create Case
                </SubmitButton>
              </Form>
            </ButtonWrapper>
          </ContentWrapper>
        </ModalContextProvider>
      </BackgroundWrapper>
    </>
  );
};

// TODO: this should be handled elsewhere, but that would involve deeper refactoring
const BackgroundWrapper = styled.div`
  background-color: ${constants.globalStyles.backgroundColor};
  height: calc(100vh - ${constants.globalStyles.headerHeight} - ${constants.globalStyles.filterHeight});
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content;
  justify-content: end;
`;

const Cell = styled.div`
  align-self: center;
  ${grid};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 3rem;
  margin: 0 auto;
  padding: 3rem 0;
  width: ${constants.globalStyles.detailsMaxWidth};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

const Wrapper = styled.div`
  display: grid;
  grid-column: 1 / -1;
`;

const ObservedCaseCreate = observer(CaseCreate);
export { ObservedCaseCreate as CaseCreate };
