import { camelCase } from 'lodash';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Flex, usePageTitle } from '../ui-library';
import { makeIsBookingSectionAvailable } from '../../utils/make-is-booking-section-available';
import { SectionPortalContainer } from './section-portal-container';
import { useBookingState } from '../booking-context';
import { useRootStore } from '../store-provider/lib';
import { useTemplateState } from '../template-context';

const BookingReviewPanel = () => {
  const history = useHistory();

  const { BookingStore, ToastStore } = useRootStore();
  const { saveBooking, triggerValidation } = useBookingState();
  const { normalisedFields } = useTemplateState();

  const { hash: routerHash } = useLocation();

  usePageTitle('Review Booking');
  // handling if there's a hash present in the url on page load
  useEffect(() => {
    const { hash } = window.location;

    if (hash) {
      const id = hash.replace('#', '');

      const sectionId = camelCase(id);

      const element = document.getElementById(sectionId);

      if (element) {
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }, [routerHash]);

  const handleOnSaveDraft = async () => {
    await saveBooking();

    BookingStore.loadBookings();

    history.push('/bookings');
  };

  const handleOnReviewBooking = async () => {
    const valid = triggerValidation();

    if (valid) {
      const bookingID = await saveBooking();

      if (bookingID) {
        // Only navigate to review page if saveBooking call was successful.
        history.push(`/bookings/review/${bookingID}`);
      }
    } else {
      ToastStore.addToast({ variant: 'error', description: 'Missing information, please fill in all required fields' });
    }
  };

  const isBookingSectionAvailable = makeIsBookingSectionAvailable(normalisedFields);

  return (
    <Flex
      height="100%"
      flexDirection="column"
      borderRightWidth={1}
      borderRightStyle="solid"
      borderColor="borderColorOne"
      backgroundColor="greyOne"
    >
      <SectionOverview flex={1}>
        {isBookingSectionAvailable('booking-details') && <SectionPortalContainer id="bookingDetails" />}
        {isBookingSectionAvailable('schedule') && <SectionPortalContainer id="schedule" />}
        {isBookingSectionAvailable('pick-up') && <SectionPortalContainer id="pickUp" />}
        {isBookingSectionAvailable('delivery') && <SectionPortalContainer id="delivery" />}
        {isBookingSectionAvailable('rail-transport') && <SectionPortalContainer id="railTransport" />}
        {isBookingSectionAvailable('cargo') && <SectionPortalContainer id="cargo" />}
        {isBookingSectionAvailable('special-equipment') && <SectionPortalContainer id="specialEquipment" />}
        {isBookingSectionAvailable('supporting-documents') && <SectionPortalContainer id="supportingDocuments" />}
      </SectionOverview>
      <ButtonWrapper
        py={2}
        px={3}
        borderTopWidth={1}
        borderTopStyle="solid"
        borderColor="borderColorOne"
        backgroundColor="white"
      >
        <Button flex={1} mr="0.5rem" onClick={handleOnSaveDraft} variant="tertiary">
          Save & Exit
        </Button>
        <Button flex={1} ml="0.5rem" onClick={handleOnReviewBooking}>
          Review Booking
        </Button>
      </ButtonWrapper>
    </Flex>
  );
};

const SectionOverview = styled.div`
  height: calc(100vh - 203px);
  overflow-y: auto;
`;

const ButtonWrapper = styled(Flex)`
  position: sticky;
  bottom: 0;
  box-shadow: inset 0px -1px 0px ${({ theme }) => theme.colors.borderColorOne};
`;

export { BookingReviewPanel };
