import { useEffect, useState } from 'react';

import { getAbsoluteETA } from '../../utils/getAbsoluteETA';
import { useRootStore } from '../../components/store-provider/lib';

const useFetchLocation = (shipmentId, delay = 2 * 1000 * 60) => {
  const { ShipmentStore } = useRootStore();
  const { loadShipmentLocation } = ShipmentStore;
  const [location, setLocation] = useState({});
  const [lastUpdatedLocationTime, setLastUpdateLocationTime] = useState(new Date());
  const [isFetching, setIsFetching] = useState(true);
  const [eta, setEta] = useState();

  useEffect(() => {
    const fetchLocationData = async () => {
      setIsFetching(true);

      const newLocation = await loadShipmentLocation(shipmentId);

      setIsFetching(false);

      setLocation(newLocation);
      if (newLocation) {
        const absoluteEta = getAbsoluteETA(newLocation.travelDurationTraffic);
        setLastUpdateLocationTime(new Date());
        setEta(absoluteEta);
      }
    };

    // Need to fetch immediately on first call, not wait for delay interval before first fetch
    fetchLocationData();
    const id = setInterval(fetchLocationData, delay);
    return () => {
      clearInterval(id);
    };
  }, [loadShipmentLocation, shipmentId, delay]);

  return { ...location, eta, isFetching, lastUpdatedLocationTime };
};

export { useFetchLocation };
