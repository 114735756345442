import { addSeconds, format } from 'date-fns';

const getAbsoluteETA = (etaSeconds = 0) => {
  const currentTime = new Date();
  const expectedTime = addSeconds(currentTime, etaSeconds);

  const timeToDisplay = format(expectedTime, 'h:mm a');

  return timeToDisplay;
};

export { getAbsoluteETA };
