const colors = {
  white: '#FFFFFF',
  black: '#000000',
  greyOne: '#FAFBFB',
  greyTwo: '#F4F5F6',
  greyThree: '#DBDEE1',
  greyFour: '#A8AFB7',
  greyFive: '#616E7C',
  greySix: '#576370',
  greySeven: '#49525D',
  greyEight: '#2C3238',
  greyNine: '#22262B',
  greyTen: '#121417',
  primaryOne: '#FFFBCC',
  primaryTwo: '#FFF599',
  primaryThree: '#FFEE66',
  primaryFour: '#FFE83F',
  primaryFive: '#FFDD00',
  primarySix: '#DBBB00',
  primarySeven: '#B79A00',
  primaryEight: '#937A00',
  primaryNine: '#7A6300',
  primaryTen: '#675400',
  successOne: '#F0FCE5',
  successTwo: '#DEF7C6',
  successThree: '#C7F1A0',
  successFour: '#AEEB76',
  successFive: '#99E652',
  successSix: '#84E028',
  successSeven: '#73CF1F',
  successEight: '#5BBA10',
  successNine: '#42A600',
  successTen: '#008300',
  infoOne: '#E0F8FE',
  infoTwo: '#B0EBFC',
  infoThree: '#7ADEFC',
  infoFour: '#35D1FC',
  infoFive: '#00C6FE',
  infoSix: '#00BBFF',
  infoSeven: '#00ADF2',
  infoEight: '#0099DF',
  infoNine: '#0088CB',
  infoTen: '#0067AC',
  interactionOne: '#E7F6FE',
  interactionTwo: '#A2DCFE',
  interactionThree: '#5CC2FD',
  interactionFour: '#17A8FD',
  interactionFive: '#0092E6',
  interactionSix: '#0075B9',
  interactionSeven: '#0066A2',
  interactionEight: '#004973',
  interactionNine: '#003B5C',
  interactionTen: '#001E2E',
  interactionHalf: '#F0F9FE',
  warningOne: '#FFF5D8',
  warningTwo: '#FFEAB2',
  warningThree: '#FFDD80',
  warningFour: '#FFD04C',
  warningFive: '#FFC525',
  warningSix: '#FFBB00',
  warningSeven: '#FFAD00',
  warningEight: '#FF9A00',
  warningNine: '#FE8903',
  warningTen: '#FE6806',
  dangerOne: '#FFEBEE',
  dangerTwo: '#FFCCD2',
  dangerThree: '#F6989A',
  dangerFour: '#EE6F72',
  dangerFive: '#F94B4E',
  dangerSix: '#FF3632',
  dangerSeven: '#F02A32',
  dangerEight: '#DE1D2C',
  dangerNine: '#D11224',
  dangerTen: '#C30016',
  borderColorOne: '#ECECEC',
  borderColorTwo: '#DFDFDF',
  borderColorThree: '#BCBCBC',
  borderColorFour: '#e3e3e3',
  currentMilestoneBackground: '#F2FFF4',
};

const colorsBevChain = {
  ...colors,
  primaryOne: '#FEEAD7',
  primaryTwo: '#FDD8B5',
  primaryThree: '#F9AC71',
  primaryFour: '#FA7633',
  primaryFive: '#E76423',
  primarySix: '#AF4A18',
  primarySeven: '#8D3C14',
  primaryEight: '#6B2E0F',
  primaryNine: '#4A1F0B',
  primaryTen: '#281106',
};

// Semantic colours for shipment delivery labels
const statusColors = {
  defaultBackground: colors.greyTwo,
  defaultColor: colors.interactionNine,
  deliveredBackground: colors.successOne,
  futileBackground: colors.dangerFour,
  futileColor: colors.white,
  inTransitBackground: colors.primaryOne,
  lastMileBackground: colors.warningTwo,
  cancelledBackground: '#FFF5F6',
  cancelledColor: colors.dangerEight,
  processedBackground: colors.interactionOne,
  processedColor: colors.interactionNine,
};

export { colors, colorsBevChain, statusColors };
