import { toNumber } from 'lodash';
import { useEffect } from 'react';

import { form } from '../../../ui-library';
import { useTemplateState } from '../../../template-context';

const { useFormState } = form;

const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

// monitor item dimensions and update volume in form when they change
const useVolumeCalculator = () => {
  const { setValue, watch } = useFormState();
  const { apiFields } = useTemplateState();

  const itemHeight = apiFields.itemHeight ? watch(apiFields.itemHeight, 0) : 0;
  const itemLength = apiFields.itemLength ? watch(apiFields.itemLength, 0) : 0;
  const itemWidth = apiFields.itemWidth ? watch(apiFields.itemWidth, 0) : 0;

  const itemVolumeUOM = apiFields.itemVolumeUOM ? watch(apiFields.itemVolumeUOM, '') : '';
  const itemDimsUOM = apiFields.itemDimsUOM ? watch(apiFields.itemDimsUOM, '') : '';

  useEffect(() => {
    if (apiFields.itemVolume && itemVolumeUOM && itemDimsUOM) {
      // if volume and dimensions are in different factors, we need to correct the calculated volume.
      const factor =
        ['CBM', 'CBL', 'M3', 'L3'].includes(itemVolumeUOM.toUpperCase()) && itemDimsUOM.toUpperCase() === 'CM'
          ? 100 * 100 * 100
          : 1;

      const volume = (toNumber(itemHeight) * toNumber(itemLength) * toNumber(itemWidth)) / factor;

      setValue(apiFields.itemVolume, roundToTwoDecimals(toNumber(volume)));
    }
  }, [apiFields.itemVolume, itemDimsUOM, itemHeight, itemLength, itemVolumeUOM, itemWidth, setValue]);
};

export { useVolumeCalculator };
