import { find } from 'lodash';

export const findMilestone = (milestoneKey, shipmentDetail) => {
  const { milestones = [] } = shipmentDetail || {};

  // handle cases where a milestone may be set to null.
  const availableMilestones = milestones.filter(Boolean);

  return find(availableMilestones, ({ status }) => status === milestoneKey);
};
