import { Controller } from 'react-hook-form';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

import { Box } from '../../../grid';
import { ErrorLabel } from '../error-label';
import { SelectDropdown } from './select-dropdown';
import { useFormState } from '../..';

const Select = ({ id, isMulti, name, validation, ...props }) => {
  const { control, getError, watch } = useFormState();

  const error = getError(name);

  const defaultValue = watch(name);

  return (
    <Box>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={validation}
        render={({ field: { onChange, value } }) => (
          <SelectDropdown
            id={id}
            isMulti={isMulti}
            hasError={!isNil(error)}
            onChange={onChange}
            selected={value}
            {...props}
          />
        )}
      />
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </Box>
  );
};

Select.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  validation: PropTypes.shape(),
};

Select.defaultProps = {
  disabled: false,
  isMulti: false,
  placeholder: 'Select...',
  validation: null,
};

export { Select, SelectDropdown as UncontrolledSelect };
