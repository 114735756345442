import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { constants, LoadMoreButton, ShowOn, Table } from '../ui-library';
import { NumbersModal } from '../numbers-modal';
import { ShipmentRow } from './shipment-row';
import { useNumbersModal } from '../../hooks/use-numbers-modal';
import { useRootStore } from '../store-provider/lib';

const { TableBody, TableRow, TableLegend, TableCell, TableLoading, NoResults } = Table;

const ShipmentListing = () => {
  const { openModal, isModalOpen, closeModal, referenceNumbers, title } = useNumbersModal();
  const { ShipmentStore } = useRootStore();
  const { loadMore, loadShipments, isFetching, activeParameters, start, setStart } = ShipmentStore;

  const [shipments, setShipments] = useState([]);
  const [totalResults, setTotalResults] = useState(0);

  const clearResults = useRef(false);

  const paramString = new URLSearchParams(activeParameters);

  useEffect(() => {
    const fetchShipments = async () => {
      // If this is a new search, reset the start value.
      const shipmentStart = clearResults.current ? 0 : start;

      const { pagination, shipments: shipmentData } = await loadShipments({
        ...activeParameters,
        start: shipmentStart,
      });

      if (clearResults.current) {
        setShipments(shipmentData);
      } else {
        setShipments([...shipments, ...shipmentData]);
      }

      setTotalResults(pagination.total);
      setStart(shipmentStart);

      clearResults.current = true;
    };

    if (clearResults.current) {
      setShipments([]);
    }

    const { dateFrom, dateTo, keyword } = activeParameters;

    if ((dateFrom && dateTo) || keyword) {
      fetchShipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramString.toString()]);

  const showShipmentData = shipments && shipments.length && isArray(shipments);

  return (
    <Wrapper>
      <ShowOn screenSize="desktop">
        <TableLegend>
          <TableCell>Shipment ID</TableCell>
          <TableCell style={{ textAlign: 'center' }}>Reference #</TableCell>
          <TableCell style={{ textAlign: 'center' }}>Purchase Order #</TableCell>
          <TableCell>Pick Up</TableCell>
          <TableCell>Delivery</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Downloads</TableCell>
        </TableLegend>
      </ShowOn>
      <TableBody>
        {showShipmentData
          ? shipments.map((item) => <ShipmentRow data={item} key={item.shipmentId} openNumbersModal={openModal} />)
          : null}
        {!showShipmentData && !isFetching && <NoResults>No shipments found</NoResults>}
        {isFetching && <TableLoading cols={7} />}
      </TableBody>
      <LoadMoreButton
        totalResults={totalResults}
        count={shipments.length}
        isFetching={isFetching}
        onSubmit={() => {
          clearResults.current = false;
          loadMore();
        }}
      >
        Load more shipments
      </LoadMoreButton>
      <NumbersModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        referenceNumbers={referenceNumbers}
        title={title}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${TableRow} {
    @media (min-width: ${constants.breakpoints.md}) {
      grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr 1fr 0.5fr;
    }
  }
`;

const ObservedShipmentListing = observer(ShipmentListing);
export { ObservedShipmentListing as ShipmentListing };
