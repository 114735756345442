import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { form } from '../../ui-library';

const { useFormState } = form;

const Literal = ({ name, transform }) => {
  const { control } = useFormState();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => {
        if (value) return transform(value);

        return null;
      }}
    />
  );
};

Literal.propTypes = {
  name: PropTypes.string.isRequired,
  transform: PropTypes.func,
};

Literal.defaultProps = {
  transform: (x) => x,
};

export { Literal };
