import { isUndefined } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { attachmentPropType } from '../../prop-types';
import { Cross, File, LoadingIndicator } from '../icons';
import { DownloadableWrapper } from './downloadable-wrapper';
import { formatBytes } from '../../utils/format-bytes';
import { useMounted } from '../../hooks/use-mounted';

const Attachment = ({ handleAttachmentDownload, attachment, isCancelable, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const mounted = useMounted();

  // NB: filesize is only known for pre-uploaded files
  // SWAG does not yet return filesize for existing files.
  const fileSize = !isUndefined(attachment.size) ? ` - (${formatBytes(attachment.size)})` : '';
  const fileName = attachment.name || attachment.FileName;

  const handleCancel = () => {
    if (isUndefined(attachment.size)) onCancel(fileName);
    onCancel(fileName, attachment.size);
  };

  const onClick = async () => {
    setIsLoading(true);

    await handleAttachmentDownload(attachment);

    if (mounted) {
      setIsLoading(false);
    }
  };

  return (
    <AttachmentContainer>
      <DownloadableWrapper isCancelable={isCancelable} onClick={onClick}>
        <FileIcon />
        <FileName>{`${fileName}${fileSize}`}</FileName>
        {isLoading && <StyledLoader />}
        {isCancelable && <CrossIcon onClick={handleCancel} />}
      </DownloadableWrapper>
    </AttachmentContainer>
  );
};

const AttachmentContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.interactionSix};
  margin-bottom: 8px;
  position: relative;
`;

const FileName = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileIcon = styled(File)`
  width: 20px;
  height: 20px;
  margin-right: ${({ theme }) => theme.space.xxs};
  flex-shrink: 0;

  path {
    fill: ${({ theme }) => theme.colors.dangerFive};
  }
`;

const CrossIcon = styled(Cross)`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 0.5rem;
  margin-top: -10px;
  fill: ${({ theme }) => theme.colors.greyFive};
  cursor: pointer;

  :hover {
    fill: ${({ theme }) => theme.colors.dangerFive};
  }
`;

const StyledLoader = styled(LoadingIndicator)`
  border-color: ${({ theme }) => `${theme.colors.interactionSix} ${theme.colors.interactionSix}`} transparent
    transparent;
  margin-left: 1rem;
`;

Attachment.propTypes = {
  attachment: attachmentPropType.isRequired,
  handleAttachmentDownload: PropTypes.func,
  isCancelable: PropTypes.bool,
  onCancel: PropTypes.func,
};

Attachment.defaultProps = {
  isCancelable: false,
  handleAttachmentDownload: () => null,
  onCancel: () => null,
};

export { Attachment };
