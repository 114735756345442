import { useState } from 'react';

const useNumbersModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [referenceNumbers, setReferenceNumbers] = useState([]);
  const [title, setTitle] = useState('');

  const openModal = (numbers, newTitle = '') => {
    setReferenceNumbers(numbers);
    setModalOpen(true);
    setTitle(newTitle);
  };

  const closeModal = () => setModalOpen(false);

  return { closeModal, isModalOpen, openModal, referenceNumbers, title };
};

export { useNumbersModal };
