import { deliveryStatuses } from '../../constants/statuses';
import { findMilestone } from '../find-milestone';

const SHIPMENT_STATUS_INDEXES = {
  CREATED: 0,
  PROCESSING: 1,
  IN_TRANSIT: 2,
  LAST_MILE: 2,
  DELIVERED: 3,
};

/*
  Figuring out which milestones to show as "completed" is tricky.
  "shipmentStatus" can't be relied on, as it is absent for anonymous users, and changes to "cancelled" when a milestone is cancelled.
  "milestones" can't be relied on, as we've been told they may not always be present.
  The most reliable way is to infer "current" milestone from which fields are present where possible.

  We store this as a number, so we can easily compare whether a milestone is before or after the "current" one.
*/
export const inferCurrentMilestone = (shipmentDetail) => {
  if (shipmentDetail?.actualDeliveryDateTime) {
    return {
      index: SHIPMENT_STATUS_INDEXES.DELIVERED,
      key: deliveryStatuses.delivered.key,
    };
  }

  if (shipmentDetail?.actualLastMile) {
    return {
      index: SHIPMENT_STATUS_INDEXES.LAST_MILE,
      key: deliveryStatuses.lastMile.key,
    };
  }

  if (shipmentDetail?.actualPickupDateTime) {
    return {
      index: SHIPMENT_STATUS_INDEXES.IN_TRANSIT,
      key: deliveryStatuses.inTransit.key,
    };
  }

  if (
    shipmentDetail?.shipmentStatus === deliveryStatuses.processing.key ||
    findMilestone(deliveryStatuses.processing.key, shipmentDetail)
  ) {
    return {
      index: SHIPMENT_STATUS_INDEXES.PROCESSING,
      key: deliveryStatuses.processing.key,
    };
  }

  return {
    index: SHIPMENT_STATUS_INDEXES.CREATED,
    key: deliveryStatuses.created.key,
  };
};
