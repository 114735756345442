import { flatten } from 'lodash';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Alert } from '../../alert';
import { Attachment } from '../../attachment';

const MAXIMUM_FILESIZE = 16 * 1024 * 1024;

const ControlledInput = ({ canUploadMultiple, value, onChange }) => {
  const [errors, setErrors] = useState([]);

  const onRemove = (name, size = 0) => {
    const newValue = value.filter((file) => {
      if (file.name !== name) return true;
      if (size && file.size !== size) return true;

      return false;
    });
    onChange(newValue);
  };

  const onDropRejected = (rejectedFiles) => {
    const allErrors = rejectedFiles.map(({ errors: fileUploadErrors, file }) =>
      fileUploadErrors.reduce((acc, error) => {
        switch (error.code) {
          case 'file-invalid-type':
            return [...acc, `${file.name} is an unsupported file type.`];
          case 'file-too-large':
            return [...acc, `${file.name} exceeds maximum filesize of 16 MB.`];
          default:
            return [...acc, error.message];
        }
      }, [])
    );

    return setErrors(flatten(allErrors));
  };

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      onChange([...value, ...acceptedFiles]);
    },
    [onChange, value]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: 'image/*,.pdf,.doc,.docx,.xml,.xls,.xlsx,.eml,.csv,.msg',
    multiple: canUploadMultiple,
    maxSize: MAXIMUM_FILESIZE,
  });

  // check if you can only upload one document at a time, hide file upload area
  const showFileUpload = canUploadMultiple || !value || value.length === 0;

  return (
    <>
      {showFileUpload && (
        <DropArea {...getRootProps({ isDragActive })}>
          <input {...getInputProps()} />
          <p>
            Drag &amp; drop files here, or <Clickable>browse</Clickable>
            <small>Maximum file size 16MB</small>
          </p>
        </DropArea>
      )}
      <div>
        {value.map((file) => (
          <Attachment isCancelable onCancel={onRemove} key={file.FileName} attachment={file} />
        ))}
      </div>
      {errors.map((error) => (
        <ErrorWrapper key={error}>
          <Alert type="error">{error}</Alert>
        </ErrorWrapper>
      ))}
    </>
  );
};

const DropArea = styled.div`
  border: dashed 2px ${({ theme }) => theme.colors.greyThree};
  background-color: ${({ theme }) => theme.colors.greyOne};
  color: ${({ theme }) => theme.colors.greyFour};
  border-radius: 4px;
  text-align: center;
  padding: ${({ theme }) => theme.space.xxs};
  margin-bottom: ${({ theme }) => theme.space.xs};
  border-color: ${({ isDragActive, theme }) => isDragActive && theme.colors.greyFour};

  small {
    display: block;
    margin-top: 0.5rem;
  }
`;

const ErrorWrapper = styled.div`
  position: relative;
  margin-bottom: 4px;
`;

const Clickable = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.interactionFive};
`;

ControlledInput.propTypes = {
  canUploadMultiple: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onChange: PropTypes.func.isRequired,
};

ControlledInput.defaultProps = {
  canUploadMultiple: false,
};

export { ControlledInput };
