import PropTypes from 'prop-types';

import { useFormState } from '../..';

const HiddenInput = ({ name, ...props }) => {
  const { register } = useFormState();

  return <input {...props} type="hidden" {...register(name)} />;
};

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export { HiddenInput };
