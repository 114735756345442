import * as yup from 'yup';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Alert, Flex, form } from '../../ui-library';
import { LabelledInput } from '../../labelled-input';
import { useRootStore } from '../../store-provider/lib';

const { Form, SubmitButton } = form;

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Please provide a first name'),
  lastName: yup.string().required('Please provide a last name'),
  emailAddress: yup.string().email('Please enter a valid email address').required('Email address is required.'),
  accountId: yup.string().required('Please select an organisation'),
  phoneNumber: yup.string().matches(/[+0-9() ]+/, 'Please enter a valid phone number.'),
});

const UserDetailsForm = ({ nextPage }) => {
  const { AccountStore, AuthStore, UserStore } = useRootStore();

  const [error, setError] = useState(false);

  const { inviteUser } = UserStore;
  const { accountDetails, loadAccount } = AccountStore;
  const { isSignedIn } = AuthStore;

  const onSubmit = async (values) => {
    try {
      await inviteUser(values);

      nextPage();
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      loadAccount();
    }
  }, [isSignedIn, loadAccount]);

  const organisations = accountDetails.map(({ AccountName, AccountID }) => ({ label: AccountName, value: AccountID }));

  return (
    <Form id="1" onSubmit={onSubmit} validationSchema={validationSchema}>
      <FormRow>
        <LabelledInput label="First Name" name="firstName" type="text" required />
      </FormRow>
      <FormRow>
        <LabelledInput label="Last Name" name="lastName" type="text" required />
      </FormRow>
      <FormRow>
        <LabelledInput label="Email" name="emailAddress" type="text" required />
      </FormRow>
      <FormRow>
        <LabelledInput label="Phone Number" name="phoneNumber" type="text" required />
      </FormRow>
      <FormRow>
        <LabelledInput label="Organisation" type="select" name="accountId" options={organisations} />
      </FormRow>
      <Alert type="info">Please make sure all information is correct as this can’t be undone.</Alert>
      {error && (
        <Alert mt="2rem" type="error">
          An error occurred creating the user — please ensure all information is correct. If you keep getting errors,
          please contact Support for help.
        </Alert>
      )}
      <SubmitButtonWrapper justifyContent="flex-end">
        <SubmitButton minWidth={140}>Next</SubmitButton>
      </SubmitButtonWrapper>
    </Form>
  );
};

const FormRow = styled.div`
  margin-bottom: 3rem;
`;

const SubmitButtonWrapper = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.sm};
`;

UserDetailsForm.propTypes = {
  nextPage: PropTypes.func.isRequired,
};

const ObservedUserDetailsForm = observer(UserDetailsForm);

export { ObservedUserDetailsForm as UserDetailsForm };
