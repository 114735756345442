import PropTypes from 'prop-types';

import { Flex, form } from '../ui-library';

const { Input, Label } = form;

// Potentially this should live elsewhere and we can use it in more places
const LabelledInput = ({ label, name, required, type, validation, labelStyle, ...rest }) => {
  // checkbox and radio inputs display label inline
  const displayInline = label && ['checkbox', 'radio'].includes(type);

  if (displayInline) {
    return (
      <Input id={name} type={type} name={name} validation={validation} {...rest}>
        {label}
        {required && <span>&nbsp;*</span>}
      </Input>
    );
  }

  return (
    <Flex flexDirection="column">
      <Label htmlFor={name} style={labelStyle}>
        {label}
        {required && <span>&nbsp;*</span>}
      </Label>
      <Input aria-label={label} id={name} type={type} name={name} validation={validation} {...rest} />
    </Flex>
  );
};

LabelledInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  validation: PropTypes.shape(),
  labelStyle: PropTypes.shape(),
};

LabelledInput.defaultProps = {
  required: false,
  type: 'text',
  validation: null,
  labelStyle: null,
};

export { LabelledInput };
