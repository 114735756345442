import { isEmpty, split } from 'lodash';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { CaseRow } from './case-row';
import { constants, LoadMoreButton, Table } from '../ui-library';
import { SortableColumn } from './sortable-column';
import { useCaseListingContext } from '../../contexts/case-listing';
import { useCaseListingTemplateContext } from '../../contexts/case-listing-template';
import { useFilterContext } from '../../contexts/filter';
import { useRootStore } from '../store-provider/lib';

const { TableBody, TableRow, TableLegend, TableLoading, NoResults } = Table;

const getTableContents = ({ cases = [], columnFields, isFetching }) => {
  const loadingComponent = <TableLoading cols={6} />;

  if (isEmpty(cases)) {
    if (isFetching) {
      return loadingComponent;
    }

    return <NoResults>No cases found</NoResults>;
  }

  return isEmpty(columnFields)
    ? loadingComponent
    : cases.map((item) => <CaseRow columns={columnFields} data={item} key={item.caseID} />);
};

const CaseListing = () => {
  const { CaseStore } = useRootStore();

  const { isFetching, totalResults } = CaseStore;

  const { columns } = useCaseListingTemplateContext();
  const { cases, loadMore } = useCaseListingContext();

  const { filters, setFilters } = useFilterContext();

  // Only use template columns with an apiField that relates to an item to render the column.
  const columnFields = columns
    .filter(({ apiField }) => apiField.startsWith('caseDetails.'))
    // Remove the leading `caseDetails.` from these apiField values, as they're not required to fetch the data.
    .map(({ apiField, ...column }) => ({ ...column, apiField: apiField.replace('caseDetails.', '') }))
    // Do not return `caseStatus` column -- this will be included in the Case ID column.
    .filter(({ apiField }) => {
      return apiField !== 'caseStatus';
    });

  const [sortColumn, sortDirection] = split(filters.sort, ':');

  return (
    <Wrapper>
      <TableLegend>
        {columnFields.map((column) => {
          if (column.id === 'lastModifiedOn' || column.id === 'createdOn') {
            return (
              <SortableColumn
                key={column.id}
                label={column.name}
                isDescending={!sortColumn || sortColumn !== column.id || sortDirection === 'desc'}
                onSortChange={(isDescending) => {
                  setFilters({ sort: `${column.id}:${isDescending ? 'desc' : 'asc'}` });
                }}
                isActive={sortColumn === column.id}
              />
            );
          }

          return <RegularColumn key={column.id}>{column.name}</RegularColumn>;
        })}
      </TableLegend>
      <TableBody>{getTableContents({ cases, isFetching, columnFields })}</TableBody>
      <LoadMoreButton totalResults={totalResults} count={cases.length} onSubmit={loadMore}>
        {isFetching ? 'Loading...' : 'Load more cases'}
      </LoadMoreButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${TableRow} {
    min-height: 52px;
    padding: 0.5rem 1.5rem;

    @media (min-width: ${constants.breakpoints.md}) {
      grid-template-columns: 1.4fr 1fr;
    }
  }
`;

const RegularColumn = styled.div``;

const ObservedCaseListing = observer(CaseListing);
export { ObservedCaseListing as CaseListing };
