import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BookingFormRow } from '../../booking-form-row';
import { DottedDivider } from '../../dotted-divider';
import { Flex, form, icons, Select } from '../../../ui-library';
import { IconWrapper } from '../icon-wrapper';
import { LocationBadge } from './location-badge';
import { useBookingState } from '../../../booking-context';
import { useCustomAddress } from '../use-custom-address';
import { useTemplateState } from '../../../template-context';

const { ErrorLabel, Label, useFormState } = form;
const { LocationMarker } = icons;

const formatLocationOptions = (locations) =>
  locations.map(({ locationID, locationName, address1, suburb, state, postcode }) => {
    const address = [address1, suburb, state, postcode].filter(Boolean).join(', ');

    const displayAddress = address ? ` (${address})` : null;

    return { label: `${locationName}${displayAddress}`, value: locationID };
  });

const LocationPicker = ({ display, error, isPickUp, selectedAddress, setSelectedAddress, templateLocationKey }) => {
  const { setValue, trigger } = useFormState();
  const [selectedLocation, setSelectedLocation] = useState();
  const { isDirty, setDirty } = useBookingState();

  const { apiFields } = useTemplateState();

  const { connected, findLocation, locations, setLocations } = useCustomAddress();

  const setFormValues = useCallback(
    (values) => {
      if (values && values.locationID) {
        setSelectedLocation(values.locationID);
        setDirty(!isDirty); // to set isDirty on select dropdown
      }

      const safeSetValue = (name, key) => {
        const value = get(values, key, '');

        const apiField = apiFields[`${templateLocationKey}${name}`];

        if (apiField) {
          setValue(apiField, value);
        }
      };

      safeSetValue('LocationName', 'locationName');
      safeSetValue('LocationCode', 'locationCode');
      safeSetValue('Address1', 'address1');
      safeSetValue('Address2', 'address2');
      safeSetValue('Latitude', 'coordinates.latitude');
      safeSetValue('Longitude', 'coordinates.longitude');
      safeSetValue('Country', 'country');
      safeSetValue('Suburb', 'suburb');
      safeSetValue('State', 'state');
      safeSetValue('PostCode', 'postcode');
      safeSetValue('LocationType', 'locationType');

      trigger();
    },
    [apiFields, templateLocationKey, setValue, trigger, isDirty, setDirty]
  );

  const clearLocation = () => {
    setSelectedLocation(null);
    setSelectedAddress(null);
    setFormValues(null);
  };

  useEffect(() => {
    const newLocation = locations.find(({ locationID }) => locationID === selectedLocation);

    if (newLocation) {
      setSelectedAddress(newLocation);
      setFormValues(newLocation);
    }

    // setFormValues as a dependency will trigger an infinite loop on location select
    // eslint-disable-next-line
  }, [locations, selectedLocation, setSelectedAddress]);

  return (
    display && (
      <BookingFormRow
        isInputRow
        icon={
          <IconWrapper>
            <LocationMarker />
          </IconWrapper>
        }
        iconHeight="20px"
      >
        <LocationPickerRow>
          <Flex flexDirection="column">
            <Label htmlFor="location">Location</Label>
            {selectedAddress ? (
              <LocationBadge location={selectedAddress} onClick={clearLocation} />
            ) : (
              <Select
                id="location"
                disabled={!connected}
                isClearable
                onClear={() => {
                  setLocations([]);
                }}
                options={formatLocationOptions(locations)}
                onChange={setSelectedLocation}
                onInputChange={(value) => findLocation(value, isPickUp)}
                placeholder={connected ? 'Search for a location' : 'Please wait, connecting...'}
                selected={selectedLocation}
              />
            )}
          </Flex>
        </LocationPickerRow>
        {error && <ErrorLabel>{error}</ErrorLabel>}
        <DottedDivider mt={4} />
      </BookingFormRow>
    )
  );
};

const LocationPickerRow = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 1rem;
`;

LocationPicker.propTypes = {
  display: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isPickUp: PropTypes.bool.isRequired,
  selectedAddress: PropTypes.shape(),
  setSelectedAddress: PropTypes.func.isRequired,
  templateLocationKey: PropTypes.oneOf(['pickUp', 'delivery']).isRequired,
};

LocationPicker.defaultProps = {
  error: null,
  selectedAddress: null,
};

export { LocationPicker };
