import {
  addHours,
  endOfYesterday,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isFuture,
  isToday,
  parse,
  set,
} from 'date-fns';
import { Controller } from 'react-hook-form';
import { isNil, noop } from 'lodash';
import { space } from 'styled-system';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { backgroundStyle, borderStyle, focusStyle, sizeStyle } from '../styles';
import { Box } from '../../../grid';
import { Calendar } from '../../../calendar';
import { Calendar as CalendarIcon } from '../../../icons';
import { ErrorLabel } from '../error-label';
import { SelectDropdown } from '../select/select-dropdown';
import { useFormState } from '../..';

const TAB = 9;

const formatDateForDisplay = (date) => (date ? format(new Date(date), 'd LLL yyyy') : '');
const formatTime = (date) => (date ? format(new Date(date), 'h:mm a') : '');

const ensureFutureDate = (label) => (value) => {
  // only validate date is in future if a date is provided
  if (!value) return true;

  const dateValue = new Date(value);

  // Allows a user to select a time in the current hour.
  // eg a user can select 12pm at 12:01pm, as it will compare against 1:01pm.
  const compareDate = addHours(dateValue, 1);

  return isFuture(compareDate) || `${label} cannot be in the past.`;
};

const DateTimePicker = ({
  allowPastDates,
  dateFormat,
  datePlaceholder,
  label,
  timePlaceholder,
  disabledDays,
  name,
  options,
  setDefaultTime,
  showErrorPoppedOut,
  validation,
  ...props
}) => {
  const { control, getError, watch } = useFormState();

  const [hasFocus, setFocus] = useState(false);
  const [hasHover, setHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // Refs for later reference
  const popoutHasFocus = useRef(false);

  const error = getError(name);

  const showPicker = () => {
    setIsOpen(true);
  };

  const formatDate = (date) => format(date, dateFormat);

  const hidePicker = () => {
    popoutHasFocus.current = false;
    if (isOpen) {
      setIsOpen(false);
    }
  };

  // Don't allow user to enter any chars with the keyboard, unless its a tab
  const onKeyDown = (e) => {
    if (e.keyCode === TAB) {
      hidePicker();
    } else {
      e.preventDefault();
      showPicker();
    }
  };

  // Show datepicker on focus
  const handleInputFocus = () => {
    setFocus(true);
    showPicker();
  };

  // On input blur, hide the datepicker if not focussed
  const handleInputBlur = () => {
    setFocus(false);
    // setTimeout required to ensure blur event doesn't kick in before overlay focus
    setTimeout(() => {
      if (!popoutHasFocus.current) {
        hidePicker();
      }
    }, 1);
  };

  const handleDayClick = (onChange) => (day) => {
    hidePicker();

    onChange(day);
  };

  const handlePopoutFocus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    popoutHasFocus.current = true;
  };

  const handlePopoutBlur = () => {
    // We need to set a timeout otherwise IE will hide the overlay when
    // focusing it
    setTimeout(() => {
      popoutHasFocus.current = false;
      hidePicker();
    }, 150);
  };

  const selectedDate = watch(name);

  const modifiers = { day: parse(selectedDate, dateFormat, new Date()) };

  const handleDateChange = (onChange) => (newDate) => {
    const now = new Date();

    let hours = getHours(newDate);
    let minutes = getMinutes(newDate);

    if (isToday(newDate) && newDate < now) {
      // if the selected date is today, and the requested time has already passed
      // set the hour to one hour in the future
      hours = getHours(addHours(now, 1));
    } else {
      const [firstTime] = options;

      if (firstTime) {
        const { value } = firstTime;

        const firstTimeDate = parse(value, 'h:mm a', new Date());

        if (firstTimeDate > newDate) {
          // if the first available time occurs after the requested time, clamp the time
          hours = getHours(firstTimeDate);
          minutes = getMinutes(firstTimeDate);
        }
      }
    }

    if (selectedDate) {
      const currentDate = parse(selectedDate, dateFormat, new Date());

      const date = getDate(newDate);
      const month = getMonth(newDate);
      const year = getYear(newDate);

      const updatedDate = set(currentDate, { date, month, year, hours, minutes });

      onChange(formatDate(updatedDate));
    } else {
      const updatedDate = set(newDate, { hours, minutes });

      onChange(formatDate(updatedDate));
    }
  };

  // For time to change, we must already have a valid date selected.
  const handleTimeChange = (onChange) => (value) => {
    const currentDate = parse(selectedDate, dateFormat, new Date());

    const newTime = parse(value, 'hh:mm a', new Date());

    const hours = newTime.getHours();
    const minutes = newTime.getMinutes();

    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);

    onChange(formatDate(currentDate));
  };

  // If previous dates are not already disabled, and previous dates are not allowed,
  // set the disabledDays `before` key to yesterday.
  if (!disabledDays.before && !allowPastDates) {
    // eslint-disable-next-line no-param-reassign
    disabledDays.before = endOfYesterday();
  }

  // This is not a perfect solution just yet, as it relies on the assumption that a disabled date
  // will always fall in the past. Currently, this assumption holds for all inputs -- we don't disable
  // dates in the distant past for any reason. However, if we need to support this use-case, more work
  // will be required here.
  if (disabledDays.before) {
    // eslint-disable-next-line no-param-reassign
    validation.validate = { ...(validation.validate || {}), ensureFutureDate: ensureFutureDate(label) };
  }

  return (
    <Controller
      control={control}
      defaultValue={selectedDate}
      name={name}
      rules={validation}
      render={({ field: { onBlur, onChange, value } }) => {
        const handleChange = (event) => {
          onChange(event);

          // trigger validation
          onBlur();
        };

        return (
          <DateTimeContainer>
            <Box position="relative">
              <Box position="relative">
                <StyledDatepicker
                  aria-label={datePlaceholder}
                  hasError={!isNil(error)}
                  hasFocus={hasFocus}
                  hasHover={hasHover}
                  name={`${name}-datepicker`}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onChange={noop}
                  onKeyDown={(e) => onKeyDown(e)}
                  value={formatDateForDisplay(value, dateFormat)}
                  placeholder={datePlaceholder}
                  {...props}
                />
                <StyledCalendarIcon onClick={handleInputFocus} />
              </Box>
              <Popout isVisible={isOpen} onFocus={(e) => handlePopoutFocus(e)} onBlur={() => handlePopoutBlur()}>
                <Calendar
                  disabledDays={disabledDays}
                  name={name}
                  setDefaultTime={setDefaultTime}
                  dateRange={false}
                  numberOfMonths={1}
                  onChange={handleDayClick(handleDateChange(handleChange))}
                  modifiers={modifiers}
                  value={new Date(value)}
                />
              </Popout>
            </Box>
            <SelectDropdown
              disabled={!value} // do not allow time to be changed before a date is selected
              options={options}
              placeholder={timePlaceholder}
              id={`${name}-time`}
              onChange={handleTimeChange(handleChange)}
              selected={formatTime(value, dateFormat)}
            />
            {error && (
              <ErrorContainer>
                <ErrorLabel showPoppedOut={showErrorPoppedOut}>{error}</ErrorLabel>
              </ErrorContainer>
            )}
          </DateTimeContainer>
        );
      }}
    />
  );
};

const ErrorContainer = styled.div`
  grid-column: span 2;
`;

const DateTimeContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
`;

const StyledDatepicker = styled.input`
  ${backgroundStyle};
  ${borderStyle};
  ${focusStyle};
  ${sizeStyle};
  ${space};

  font-size: ${({ theme }) => theme.fontSize.base};

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border-color: ${theme.colors.dangerSeven};
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greyTwo};
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.greyFour};
    opacity: 1;
  }
`;

const Popout = styled(Box)`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 12px 8px 24px rgba(0, 0, 0, 0.1), -12px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  font-size: ${({ theme }) => theme.fontSize.small};
  overflow-y: auto;
  width: fit-content;
  min-width: 286px;
  position: absolute;
  z-index: 5;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  cursor: pointer;

  path {
    fill: ${(props) => props.theme.colors.black};
  }
`;

DateTimePicker.propTypes = {
  allowPastDates: PropTypes.bool,
  disabledDays: PropTypes.shape({ before: PropTypes.instanceOf(Date), after: PropTypes.instanceOf(Date) }),
  dateFormat: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
  ).isRequired,
  validation: PropTypes.shape(),
  setDefaultTime: PropTypes.func,
  showErrorPoppedOut: PropTypes.bool,
  datePlaceholder: PropTypes.string,
  timePlaceholder: PropTypes.string,
};

DateTimePicker.defaultProps = {
  allowPastDates: false,
  disabledDays: {},
  dateFormat: "yyyy-MM-dd'T'HH:mm:ss", // '' escapes the character
  validation: null,
  setDefaultTime: (x) => x,
  showErrorPoppedOut: false,
  datePlaceholder: 'Select date',
  timePlaceholder: 'Select time',
};

export { DateTimePicker };
