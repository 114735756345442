import { isEmpty, kebabCase } from 'lodash';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AddressString } from '../../../address-string';
import { BookingTab, Box, Flex, form, formatDate, icons } from '../../../ui-library';
import { CargoSectionSummary } from '../cargo-section-summary';
import { Link } from '../../../link';
import { TimeWindow } from '../../../time-window';
import { useBookingState } from '../../../booking-context';
import { useRootStore } from '../../../store-provider/lib';
import { useTemplateState } from '../../../template-context';

const { Delivery, ArrowRight, PickUp } = icons;
const { useFormState } = form;

const SectionSummary = ({ id }) => {
  const {
    BookingStore: { documents },
  } = useRootStore();

  const { search, pathname } = useLocation();

  const { formHasErrors, visibleSection } = useBookingState();

  const { templateData } = useTemplateState();

  const { watch, isValid } = useFormState();

  const currentRoute = `${pathname}${search}`;

  const isSectionInvalid = !isValid && formHasErrors;

  const determineIfActive = (sectionId) => {
    return sectionId === kebabCase(visibleSection);
  };

  const { isDirty } = useBookingState();

  switch (id) {
    case 'bookingDetails': {
      const dangerousGoods = watch('dangerousGoodsIndicator');
      const urgentFlag = watch('urgentFlag');
      const overDimensionalFlag = watch('overDimensionalFlag');

      return (
        <AnchorLink to={`${currentRoute}#booking-details`} smooth>
          <BookingTab
            title="Booking Details"
            isActive={determineIfActive('booking-details')}
            hasError={isSectionInvalid}
            labels={[
              {
                key: 'details',
                placeholder: 'Contact and Service',
                value: (
                  <Flex flexWrap="wrap">
                    <Box mr={3}>{templateData.contractName}</Box>
                    <Box mr={3}>{templateData.serviceName}</Box>
                    <Box mr={3}>{templateData.commodityName}</Box>
                    {dangerousGoods && (
                      <Box color="warningTen" mr={3}>
                        Dangerous Goods
                      </Box>
                    )}
                    {urgentFlag && (
                      <Box color="warningTen" mr={3}>
                        Urgent
                      </Box>
                    )}
                    {overDimensionalFlag && <Box color="warningTen">Over Dimensions</Box>}
                  </Flex>
                ),
              },
            ]}
          />
        </AnchorLink>
      );
    }

    case 'schedule': {
      const {
        pickupDetails: { pickupFromDateTime = '', pickupToDateTime = '' },
        deliveryDetails: { deliveryFromDateTime, deliveryToDateTime },
      } = watch();

      return (
        <AnchorLink to={`${currentRoute}#schedule`} smooth>
          <BookingTab
            title="Schedule"
            isActive={determineIfActive('schedule')}
            hasError={isSectionInvalid}
            labels={[
              {
                key: 'pickUp',
                placeholder: 'Pick up date & time',
                icon: <PickUp />,
                value: (pickupFromDateTime || pickupToDateTime) && (
                  <TimeWindow from={pickupFromDateTime} to={pickupToDateTime} />
                ),
              },
              {
                key: 'delivery',
                placeholder: 'Delivery date & time',
                icon: <Delivery />,
                value: (deliveryFromDateTime || deliveryToDateTime) && (
                  <TimeWindow from={deliveryFromDateTime} to={deliveryToDateTime} />
                ),
              },
            ]}
          />
        </AnchorLink>
      );
    }

    case 'pickUp': {
      const {
        pickupDetails: { location },
      } = watch();
      const showLabel =
        !isEmpty(location) && (location.locationName || location.address1 || location.suburb || location.state);

      return (
        <AnchorLink to={`${currentRoute}#pick-up`} smooth>
          <BookingTab
            title="Pick Up"
            isActive={determineIfActive('pick-up')}
            hasError={!isDirty && formHasErrors}
            labels={[
              {
                key: 'location',
                placeholder: 'Location',
                icon: <PickUp />,
                value: showLabel && <AddressString location={location} />,
              },
            ]}
          />
        </AnchorLink>
      );
    }

    case 'delivery': {
      const {
        deliveryDetails: { location },
      } = watch();
      const showLabel =
        !isEmpty(location) && (location.locationName || location.address1 || location.suburb || location.state);

      return (
        <AnchorLink to={`${currentRoute}#delivery`} smooth>
          <BookingTab
            title="Delivery"
            isActive={determineIfActive('delivery')}
            hasError={isSectionInvalid}
            labels={[
              {
                key: 'location',
                placeholder: 'Location',
                icon: <Delivery />,
                value: showLabel && <AddressString location={location} />,
              },
            ]}
          />
        </AnchorLink>
      );
    }

    case 'cargo': {
      const { items = [] } = watch();

      return (
        <AnchorLink to={`${currentRoute}#cargo`} smooth>
          <BookingTab
            title="Cargo"
            isActive={determineIfActive('cargo')}
            hasError={isSectionInvalid}
            labels={[
              {
                key: 'items',
                placeholder: 'Items',
                value: !isEmpty(items) && <CargoSectionSummary />,
              },
            ]}
          />
        </AnchorLink>
      );
    }

    case 'specialEquipment': {
      const { equipments } = watch();

      return (
        <AnchorLink to={`${currentRoute}#special-equipment`} smooth>
          <BookingTab
            title="Special Equipment"
            isActive={determineIfActive('special-equipment')}
            hasError={isSectionInvalid}
            labels={[
              {
                key: 'optional',
                placeholder: 'Optional',
                value: !isEmpty(equipments) && `${equipments.length} equipment added`,
              },
            ]}
          />
        </AnchorLink>
      );
    }

    case 'supportingDocuments': {
      return (
        <AnchorLink to={`${currentRoute}#supporting-documents`} smooth>
          <BookingTab
            title="Supporting Documents"
            isActive={determineIfActive('supporting-documents')}
            hasError={isSectionInvalid}
            labels={[
              {
                key: 'optional',
                placeholder: 'Optional',
                value: !isEmpty(documents) && `${documents.length} documents attached`,
              },
            ]}
          />
        </AnchorLink>
      );
    }

    case 'railTransport': {
      const {
        railTransport: { railDepartureDateTime, railOrigin, railDestination },
      } = watch();

      const showLabel = railOrigin || railDestination;

      return (
        <AnchorLink to={`${currentRoute}#rail-transport`} smooth>
          <BookingTab
            title="Rail Transport"
            isActive={determineIfActive('rail-transport')}
            hasError={isSectionInvalid}
            labels={[
              {
                key: 'location',
                placeholder: 'Origin and Destination',
                value: showLabel && (
                  <>
                    {railOrigin} <DateArrow /> {railDestination}
                  </>
                ),
              },
              {
                key: 'time',
                placeholder: 'Departure Time',
                value: formatDate(railDepartureDateTime),
              },
            ]}
          />
        </AnchorLink>
      );
    }

    default: {
      return null;
    }
  }
};

const AnchorLink = styled(({ ...props }) => <Link {...props} />)`
  text-decoration: none;
`;

const DateArrow = styled(ArrowRight).attrs(() => ({ height: 20, width: 20 }))`
  fill: ${({ theme }) => theme.colors.greySeven};
  margin: 0 0.5rem;
`;

SectionSummary.propTypes = {
  id: PropTypes.string.isRequired,
};

const ObservedSectionSummary = observer(SectionSummary);
export { ObservedSectionSummary as SectionSummary };
