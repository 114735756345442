import styled from 'styled-components';

import { Link } from '../../link';

// eslint-disable-next-line no-unused-vars
const StyledLink = styled(({ isActive, ...props }) => <Link {...props} />)`
  border-radius: 6px;
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.space.xs};
  margin: ${({ theme }) => theme.space.xs} 0;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  user-select: none;
  align-items: center;

  ${({ isActive, theme }) =>
    isActive
      ? `
          color: ${theme.colors.primaryFive};
          background: rgba(255, 255, 255, 0.2);`
      : ''}

  > svg {
    margin-right: ${({ theme }) => theme.space.xs};
    stroke: none;

    path {
      fill: ${({ isActive, theme }) => (isActive ? theme.colors.primaryFive : theme.colors.greySix)};
    }
  }
`;

export { StyledLink };
