import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex, formatDate, icons, Placeholder } from '../ui-library';

const { ArrowRight } = icons;

const TimeWindow = ({ from, to, iconFill }) => {
  const formattedFrom = formatDate(from);
  const formattedTo = formatDate(to);

  return (
    <Flex alignItems="center">
      {formattedFrom || <Placeholder>From</Placeholder>} <DateArrow fill={iconFill} />{' '}
      {formattedTo || <Placeholder>To</Placeholder>}
    </Flex>
  );
};

const DateArrow = styled(ArrowRight).attrs(() => ({ height: 20, width: 20 }))`
  fill: ${({ iconFill, theme }) => iconFill || theme.colors.greySeven};
  margin: 0 0.5rem;
`;

TimeWindow.propTypes = {
  from: PropTypes.string,
  iconFill: PropTypes.string,
  to: PropTypes.string,
};

TimeWindow.defaultProps = {
  from: null,
  iconFill: null,
  to: null,
};

export { TimeWindow };
