const getIsFutile = (futileReference) => {
  if (!futileReference) {
    return false;
  }

  if (futileReference === 'DL_DMG_INTRANS' || futileReference === 'DL_REJ_OUTLET') {
    return false;
  }

  return true;
};

export { getIsFutile };
