import { endOfDay, format, formatISO, startOfDay } from 'date-fns';
import { find } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Filter, FilterBar, icons } from '../ui-library';
import { getBrandStatus } from '../../utils/get-brand-status';
import { StatusLabel } from './status-label';
import { useBranding } from '../../contexts/branding';
import { useCaseListingTemplateContext } from '../../contexts/case-listing-template';
import { useFilterContext } from '../../contexts/filter';
import { useRootStore } from '../store-provider/lib';

const { Calendar, Location, Status, Users, Hamburger, Flag } = icons;

const CaseFilterBar = ({ handleCreateCase }) => {
  const { CaseStore } = useRootStore();
  const { columns } = useCaseListingTemplateContext();

  const { keyword } = CaseStore;

  const { clearFilters, defaultValues, filters, isFiltersActive, setFilters } = useFilterContext();

  const searchProps = {
    keyword,
    placeholder: 'Search cases by case or reference number',
    onSubmit: setFilters,
  };

  const buttonProps = {
    children: 'Create Case',
    onClick: handleCreateCase,
  };

  const createdOnFromFilter = find(columns, { id: 'createdOnFromFilter' });
  const createdOnToFilter = find(columns, { id: 'createdOnToFilter' });
  const stateFilter = find(columns, { id: 'stateFilter' });
  const caseStatusFilter = find(columns, { id: 'caseStatusFilter' });
  const caseTypeFilter = find(columns, { id: 'caseTypeFilter' });
  const priorityFilter = find(columns, { id: 'casePriorityFilter' });
  const locationFilter = find(columns, { id: 'locationFilter' });
  const contractFilter = find(columns, { id: 'contractFilter' });

  const branding = useBranding();

  return (
    <FilterBar
      searchProps={searchProps}
      isFiltersActive={isFiltersActive}
      clearFilters={clearFilters}
      buttonProps={buttonProps}
    >
      {caseStatusFilter && (
        <Filter
          id="caseStatus"
          type="radio"
          defaultChipLabel="Awaiting You"
          chipIcon={<Status />}
          data={{
            options: caseStatusFilter.options.map((option) => ({
              key: option.value,
              label: getBrandStatus(branding, option.text),
            })),
          }}
          resetKey={defaultValues.caseStatus}
          onChange={({ caseStatus }) => setFilters({ caseStatus })}
          defaultValues={{
            caseStatus: filters.caseStatus,
          }}
        >
          {({ label }) => {
            return <StatusLabel label={getBrandStatus(branding, label)} />;
          }}
        </Filter>
      )}
      {caseTypeFilter && (
        <Filter
          id="caseType"
          type="radio"
          defaultChipLabel={caseTypeFilter.name}
          chipIcon={<Hamburger />}
          data={{
            options: caseTypeFilter.options.map((option) => ({ key: option.value, label: option.text })),
          }}
          resetKey={defaultValues.caseType}
          onChange={({ caseType }) => setFilters({ caseType })}
          defaultValues={{
            caseType: filters.caseType,
          }}
        />
      )}
      {contractFilter && (
        <Filter
          id="contractID"
          type="radio"
          defaultChipLabel={contractFilter.name}
          chipIcon={<Users />}
          data={{
            options: contractFilter.options.map((option) => ({ key: option.value, label: option.text })),
          }}
          resetKey={defaultValues.contractID}
          onChange={({ contractID }) => setFilters({ contractID })}
          defaultValues={{
            contractID: filters.contractID,
          }}
        />
      )}
      {stateFilter && (
        <Filter
          id="deliveryState"
          type="radio"
          defaultChipLabel={stateFilter.name}
          chipIcon={<Location />}
          data={{
            options: stateFilter.options.map((option) => ({ key: option.value, label: option.text })),
          }}
          resetKey={defaultValues.deliveryState}
          onChange={({ deliveryState }) => setFilters({ deliveryState })}
          defaultValues={{
            deliveryState: filters.deliveryState,
          }}
        />
      )}
      {locationFilter && (
        <Filter
          id="location"
          type="input"
          defaultChipLabel={locationFilter.name}
          chipIcon={<Location />}
          data={{
            placeholder: 'Enter Location',
            validation: {
              required: 'A location is required.',
              minLength: {
                value: 3,
                message: 'Location must be longer than 3 characters.',
              },
            },
          }}
          onChange={({ location }) => setFilters({ location })}
          defaultValues={{ location: filters.location }}
        />
      )}
      {priorityFilter && (
        <Filter
          id="casePriority"
          type="radio"
          defaultChipLabel={priorityFilter.name}
          chipIcon={<Flag />}
          data={{
            options: priorityFilter.options.map((option) => ({ key: option.value, label: option.text })),
          }}
          resetKey={defaultValues.casePriority}
          onChange={({ casePriority }) => setFilters({ casePriority })}
          defaultValues={{
            casePriority: filters.casePriority,
          }}
        />
      )}
      {(createdOnFromFilter || createdOnToFilter) && (
        <Filter
          id="date"
          type="date"
          chipIcon={<Calendar />}
          defaultValues={{ createdOnTo: filters.createdOnTo, createdOnFrom: filters.createdOnFrom }}
          isActive={filters.createdOnTo || filters.createdOnFrom}
          data={{ showDayButtons: false }}
          resetKey={defaultValues.date}
          onChange={({ date: { from, to } }) => {
            const createdOnFrom = startOfDay(new Date(from));
            const createdOnTo = endOfDay(new Date(to));

            setFilters({
              createdOnFrom: from ? formatISO(createdOnFrom) : '',
              createdOnTo: to ? formatISO(createdOnTo) : '',
            });
          }}
        >
          {({ value: { createdOnFrom, createdOnTo } }) => {
            if (!createdOnFrom && !createdOnTo) {
              return createdOnFromFilter.name;
            }

            const formattedStart = format(new Date(createdOnFrom), 'd LLL');
            const formattedEnd = format(new Date(createdOnTo || createdOnFrom), 'd LLL');

            if (formattedStart === formattedEnd) {
              return formattedStart;
            }

            return `${formattedStart} → ${formattedEnd}`;
          }}
        </Filter>
      )}
    </FilterBar>
  );
};

CaseFilterBar.propTypes = {
  handleCreateCase: PropTypes.func.isRequired,
};

const ObservedCaseFilterBar = observer(CaseFilterBar);
export { ObservedCaseFilterBar as CaseFilterBar };
