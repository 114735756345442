/* eslint-disable react/prop-types */
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  CancelledCircle,
  Circle,
  ColumnWrapper,
  DateWrapper,
  DescWrapper,
  EtaWrapper,
  InPastCircle,
  Line,
  LoadingIndicatorWrapper,
  MilestoneInnerWrapper,
  MilestoneWrapper,
  StatusWrapper,
  StyledDownloadIcon,
  SubSection,
} from '../styles';
import { constants, formatDateEta, icons, ShowOn } from '../../ui-library';
import { deliveryStatuses } from '../../../constants/statuses';
import { PodDownloadLink } from '../../pod-download-link';
import { PopDownloadLink } from '../../pop-download-link';
import { ShowMapComponent } from '../../bing-map';
import { useFeatureFlag } from '../../../hooks/use-feature-flag';
import { useHasPrivilege } from '../../../hooks/use-has-privilege';
import { useMapState } from '../../bing-map/map-context';
import { useRootStore } from '../../store-provider/lib';

const { Cross, Tick, Truck, LoadingIndicator } = icons;

const DateComponent = ({
  actualDate,
  estimatedRangeStartDate,
  estimatedRangeEndDate,
  podID,
  popID,
  isInLastMile,
  setShowMap,
}) => {
  const dateString = formatDateEta(estimatedRangeStartDate, estimatedRangeEndDate, actualDate);

  if (isInLastMile) {
    return <ShowMapComponent onClick={() => setShowMap(true)} title="Show on Map" />;
  }

  return (
    <DateWrapper>
      {dateString}
      {!isEmpty(podID) && (
        <PodDownloadLink podID={podID} variant="detail">
          <StyledDownloadIcon />
          POD
        </PodDownloadLink>
      )}
      {!isEmpty(popID) && (
        <PopDownloadLink popID={popID} variant="detail">
          <StyledDownloadIcon />
          POP
        </PopDownloadLink>
      )}
    </DateWrapper>
  );
};

const buildIconCircle = (isCurrentMilestone, title, isCancelledMilestone, milestoneIsInFuture) => {
  let IconComponent = Tick;
  let CircleComponent = milestoneIsInFuture ? Circle : InPastCircle;

  if ((title === deliveryStatuses.inTransit.label || deliveryStatuses.lastMile.label) && isCurrentMilestone) {
    IconComponent = Truck;
  }

  if (isCancelledMilestone) {
    IconComponent = Cross;
    CircleComponent = CancelledCircle;
  }

  return (
    <CircleComponent>
      <IconComponent />
    </CircleComponent>
  );
};

const buildTitle = (isCurrentMilestone, title) => {
  if (title === deliveryStatuses.lastMile.label && isCurrentMilestone) {
    return `${deliveryStatuses.inTransit.label} - ${deliveryStatuses.lastMile.label}`;
  }

  return title;
};

const LastMileDescription = () => {
  const { eta, isFetching, lastUpdatedLocationTime } = useMapState();

  if (isFetching)
    return (
      <LoadingIndicatorWrapper>
        <LoadingIndicator />
      </LoadingIndicatorWrapper>
    );

  return (
    <>
      <EtaWrapper>ETA {eta}</EtaWrapper>
      {lastUpdatedLocationTime && (
        <DateWrapper style={{ marginTop: '6px' }}>
          Last Updated at {format(lastUpdatedLocationTime, 'h:mm a')}
        </DateWrapper>
      )}
    </>
  );
};

const Description = ({ isCurrentMilestone, enableLastMile, title }) => {
  if (title === deliveryStatuses.inTransit.label && isCurrentMilestone) {
    return <>Live ETA available once driver is en route</>;
  }

  if (title === deliveryStatuses.lastMile.label && isCurrentMilestone && enableLastMile) {
    return <LastMileDescription />;
  }

  return null;
};

const ShipmentMilestone = ({
  title,
  estimatedStartDate,
  estimatedEndDate,
  actualDate,
  index,
  currentMilestoneIndex,
  isCancelled,
  isInLastMile,
  isInTransit,
  setGap,
  podID,
  popID,
  setShowMap,
}) => {
  const { contractPrivileges } = constants;

  const isCancelledMilestone = title === deliveryStatuses.cancelled.key;
  const isCurrentMilestone = currentMilestoneIndex === index && !isCancelled;
  const milestoneIsInFuture = currentMilestoneIndex < index;

  const { ShipmentStore } = useRootStore();
  const lastMileFeatureEnabled = useFeatureFlag('LAST_MILE');
  const userHasLastMilePrivilege = useHasPrivilege(contractPrivileges.trackLastMile);
  const guestHasLastMileAnonymousKey = !!ShipmentStore.anonymousKey;
  const enableLastMile = lastMileFeatureEnabled && (userHasLastMilePrivilege || guestHasLastMileAnonymousKey);

  const hideMilestoneDueToCancelledShipment = isCancelled && milestoneIsInFuture;
  const iconCircle = buildIconCircle(isCurrentMilestone, title, isCancelledMilestone, milestoneIsInFuture);
  const titleLine = buildTitle(isCurrentMilestone, title);

  const descriptionLine = (
    <Description isCurrentMilestone={isCurrentMilestone} title={title} enableLastMile={enableLastMile} />
  );

  const showLine = index !== 0;

  const dateComponent = (
    <DateComponent
      actualDate={actualDate}
      estimatedRangeStartDate={estimatedStartDate}
      estimatedRangeEndDate={estimatedEndDate}
      podID={podID}
      popID={popID}
      isInLastMile={isInLastMile}
      setShowMap={setShowMap}
    />
  );

  const milestoneBody = (
    <>
      <ShowOn screenSize="mobile" height="100%">
        <MilestoneInnerWrapper
          isCurrentMilestone={isCurrentMilestone}
          data-testid="shipment-milestone-mobile"
          {...{ isInTransit, isInLastMile, index }}
        >
          <StatusWrapper isCurrentMilestone={isCurrentMilestone}>{iconCircle}</StatusWrapper>
          <ColumnWrapper>
            <SubSection>{titleLine}</SubSection>
            {(isInTransit || isInLastMile) && <DescWrapper>{descriptionLine}</DescWrapper>}
            <SubSection>{dateComponent}</SubSection>
          </ColumnWrapper>
        </MilestoneInnerWrapper>
      </ShowOn>
      <ShowOn screenSize="desktop" height="100%">
        <MilestoneInnerWrapper isCurrentMilestone={isCurrentMilestone} data-testid="shipment-milestone">
          <SubSection>{titleLine}</SubSection>
          <SubSection>
            <StatusWrapper isCurrentMilestone={isCurrentMilestone}>
              {isInTransit || (isInLastMile && enableLastMile) ? descriptionLine : iconCircle}
            </StatusWrapper>
          </SubSection>
          <SubSection>{dateComponent}</SubSection>
        </MilestoneInnerWrapper>
      </ShowOn>
    </>
  );

  return (
    <>
      <MilestoneWrapper {...{ index, isCurrentMilestone, hideMilestoneDueToCancelledShipment, setGap }}>
        {milestoneBody}
      </MilestoneWrapper>
      {showLine && (
        <Line
          {...{
            index,
            setGap,
            milestoneIsInFuture,
            showCancelledLine: hideMilestoneDueToCancelledShipment || isCancelledMilestone,
          }}
        />
      )}
    </>
  );
};

ShipmentMilestone.propTypes = {
  title: PropTypes.string.isRequired,
  estimatedStartDate: PropTypes.string,
  estimatedEndDate: PropTypes.string,
  actualDate: PropTypes.string,
  index: PropTypes.number.isRequired,
  currentMilestoneIndex: PropTypes.number.isRequired,
  isCancelled: PropTypes.bool,
  isInLastMile: PropTypes.bool,
  isInTransit: PropTypes.bool,
  isInDelivery: PropTypes.bool,
  isInFutile: PropTypes.bool,
  setShowMap: PropTypes.func,
  setGap: PropTypes.string,
  podID: PropTypes.string,
  popID: PropTypes.string,
  lastUpdatedLocationTime: PropTypes.instanceOf(Date),
};

ShipmentMilestone.defaultProps = {
  estimatedStartDate: null,
  estimatedEndDate: null,
  actualDate: null,
  isCancelled: false,
  isInLastMile: false,
  isInTransit: false,
  isInDelivery: false,
  isInFutile: false,
  setGap: null,
  setShowMap: null,
  podID: null,
  popID: null,
  lastUpdatedLocationTime: null,
};

export { ShipmentMilestone };
