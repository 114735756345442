import { PropTypes } from 'prop-types';
import { set } from 'date-fns';
import styled from 'styled-components';

import { DynamicFormInput } from '../../dynamic-form-input';
import { icons } from '../../ui-library';
import { LabelledInput } from '../../labelled-input';
import { useClampDateTimes } from './use-clamp-date-times';

const { DoubleArrowRight } = icons;

const DateTimeWindowPicker = ({ from, to, earliestDate }) => {
  const { disabledFrom, disabledTo, fromTimes, toTimes } = useClampDateTimes(from, to, earliestDate);

  return (
    <DateTimeWindowContainer>
      <DynamicFormInput
        name={from}
        render={({ label, name, type, validation, required }) => (
          <LabelledInput
            disabledDays={disabledFrom}
            label={label}
            type={type}
            name={name}
            options={fromTimes}
            setDefaultTime={(date) => {
              return set(date, { hours: 9, minutes: 0 });
            }}
            validation={validation}
            datePlaceholder="Start date"
            timePlaceholder="Start time"
            required={required}
          />
        )}
      />
      <DoubleArrowRightIcon />
      <DynamicFormInput
        name={to}
        render={({ label, name, type, validation, required }) => (
          <LabelledInput
            disabledDays={disabledTo}
            label={label}
            type={type}
            name={name}
            options={toTimes}
            setDefaultTime={(date) => {
              return set(date, { hours: 17, minutes: 0 });
            }}
            validation={validation}
            datePlaceholder="End date"
            timePlaceholder="End time"
            required={required}
          />
        )}
      />
    </DateTimeWindowContainer>
  );
};

const DateTimeWindowContainer = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr max-content 1fr;
`;

const DoubleArrowRightIcon = styled(DoubleArrowRight)`
  path {
    fill: ${({ theme }) => theme.colors.greyFour};
  }
`;

DateTimeWindowPicker.propTypes = {
  earliestDate: PropTypes.instanceOf(Date).isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export { DateTimeWindowPicker };
