import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';

import { BrandingProvider, useBranding } from './contexts/branding';
import { constants, GlobalCSS, UiLibraryProvider } from './components/ui-library';
import { Layout } from './components/layout';
import { Link } from './components/link';
import { logger } from './utils/logger';
import { Login } from './components/login';
import { Privilege } from './components/privilege';
import { StoreProvider } from './components/store-provider';
import { initGA } from './utils/tracking';

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (branding) => ({
  ...constants.theme,
  colors: branding === 'bevChain' ? constants.colorsBevChain : constants.colors,
});

const App = () => {
  const branding = useBranding();
  const queryClient = new QueryClient();
  useEffect(() => {
    if (branding === 'bevChain') {
      initGA(process.env.REACT_APP_GA_MEASUREMENT_ID_BEVCHAIN);
    } else {
      initGA(process.env.REACT_APP_GA_MEASUREMENT_ID_PORTAL);
    }
  });

  return (
    <StoreProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Login />
          <BrandingProvider>
            <UiLibraryProvider
              branding={branding}
              theme={getTheme(branding)}
              LinkComponent={Link}
              PrivilegeComponent={Privilege}
              logger={logger}
              >
              <GlobalCSS />
              <Layout />
            </UiLibraryProvider>
          </BrandingProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </StoreProvider>
  );
};

export default App;
