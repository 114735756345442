import PropTypes from 'prop-types';
import styled from 'styled-components';

import { responsiveStyles } from '../ui-library';

const { mobileOnly } = responsiveStyles;

const TextLink = ({ text, onClick, align }) => {
  const wrapperOnClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick(event);
  };
  return (
    <Wrapper onClick={wrapperOnClick} style={{ justifyContent: align }}>
      {text}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.colors.interactionSix};

  ${mobileOnly} {
    margin-top: auto;
    margin-left: 12px;
  }
`;

TextLink.propTypes = {
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  align: PropTypes.oneOf(['right', 'left', 'center']),
};

TextLink.defaultProps = {
  align: 'center',
};

export { TextLink };
