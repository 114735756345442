import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Breadcrumbs,
  constants,
  Content,
  ContentHeaderWrapper,
  DetailsList,
  formatDateEta,
  H2,
  NotFound,
  responsiveStyles,
  usePageTitle,
} from '../../components/ui-library';
import { ErrorMessage } from '../../components/ui-library/components/error-message';
import { getIsInLastMile } from '../../utils/get-is-in-last-mile';
import { HeaderBar } from '../../components/header-bar';
import { MapContextProvider } from '../../components/bing-map/map-context';
import { NumbersModal } from '../../components/numbers-modal';
import { processReferenceNumbers } from '../../utils/render-reference-numbers';
import { ShipmentMilestones } from '../../components/shipment-milestones';
import { TextLink } from '../../components/text-link';
import { useFeatureFlag } from '../../hooks/use-feature-flag';
import { useHasPrivilege } from '../../hooks/use-has-privilege';
import { useNumbersModal } from '../../hooks/use-numbers-modal';
import { useRootStore } from '../../components/store-provider/lib';

const EMPTY_FIELD_PLACEHOLDER = '-';

const { desktopOnly } = responsiveStyles;
const { DetailsLoading, DetailsTitle, DetailsListItem } = DetailsList;
const { contractPrivileges } = constants;

const TrackAndTraceDetail = () => {
  usePageTitle('Track and Trace');
  const { isModalOpen, closeModal, referenceNumbers, openModal } = useNumbersModal();
  const { shipmentId } = useParams();
  const { ShipmentStore } = useRootStore();
  const [data, setData] = useState();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await ShipmentStore.loadShipmentDetail(shipmentId);

      setHasLoaded(true);
      if (response.error) {
        setError(response.error);
      } else if (response.data) {
        setData(response.data);
      } else {
        setError('There was an error accessing this shipment, please try again.');
      }
    };

    fetchData();
  }, [ShipmentStore, shipmentId]);

  const hasAccessToTrackTraceListing = useHasPrivilege(contractPrivileges.track);

  const lastMileFeatureEnabled = useFeatureFlag('LAST_MILE');
  const userHasLastMilePrivilege = useHasPrivilege(contractPrivileges.trackLastMile);
  const guestHasLastMileAnonymousKey = !!ShipmentStore.anonymousKey;
  const enableLastMile = lastMileFeatureEnabled && (userHasLastMilePrivilege || guestHasLastMileAnonymousKey);

  const breadCrumbTrail = hasAccessToTrackTraceListing
    ? {
        title: 'Tracking Details',
        ancestors: [
          {
            title: 'Track & Trace',
            url: '/track',
          },
        ],
      }
    : { ancestors: [{ title: 'Back to Home', url: '/' }] };

  const deliveryTime = formatDateEta(
    get(data, 'plannedDeliveryDateTimeFrom'),
    get(data, 'plannedDeliveryDateTimeTo'),
    get(data, 'actualDeliveryDateTime')
  );

  const pickupTime = formatDateEta(
    get(data, 'plannedPickupDateTimeFrom'),
    get(data, 'plannedPickupDateTimeTo'),
    get(data, 'actualPickupDateTime')
  );

  const processedReferenceNumbers = processReferenceNumbers(data?.customerReference ?? '');

  const isInLastMile = getIsInLastMile({ enableLastMile, shipmentDetail: data });

  return (
    <>
      <HeaderBar>
        <Breadcrumbs entry={breadCrumbTrail} />
      </HeaderBar>
      <Content maxWidth={constants.globalStyles.detailsMaxWidth}>
        {!hasLoaded && <DetailsLoading showMilestones rows={4} />}
        {hasLoaded && !data && !error && <NotFound type="Shipment" identifier={shipmentId} />}
        {hasLoaded && !data && error && <ErrorMessage>{error}</ErrorMessage>}
        {hasLoaded && data && !error && (
          <MapContextProvider
            deliveryLocation={data.deliveryLocation}
            isInLastMile={isInLastMile}
            shipmentId={shipmentId}
          >
            <ContentHeaderWrapper>
              <PageTitle data-testid={`track-${data.shipmentId}`}>
                {data.shipmentId}
                <small>Shipment ID</small>
              </PageTitle>
              <PageTitle rightAligned>
                {processedReferenceNumbers.firstNumber}
                <small>Reference #</small>
                {processedReferenceNumbers.isArray && data.customerReference.length > 1 ? (
                  <TextLink text="See all" onClick={() => openModal(data.customerReference)} align="right" />
                ) : (
                  ''
                )}
              </PageTitle>
            </ContentHeaderWrapper>
            <ShipmentMilestones shipmentDetail={data} shipmentId={shipmentId} />
            <StyledDetailsTitle>Pickup &amp; Delivery</StyledDetailsTitle>
            <DetailsListItem title="Pickup Location">
              {isEmpty(data.pickupLocation) ? (
                EMPTY_FIELD_PLACEHOLDER
              ) : (
                <>
                  {data.pickupLocation.name}
                  <br />
                  {data.pickupLocation.street} {data.pickupLocation.suburb} {data.pickupLocation.state}{' '}
                  {data.pickupLocation.postcode}
                </>
              )}
            </DetailsListItem>
            <DetailsListItem title="Pickup Time">
              {isEmpty(pickupTime) ? (
                EMPTY_FIELD_PLACEHOLDER
              ) : (
                <>
                  {pickupTime}
                  {!data.actualPickupDateTime && <DateTimeStatus>(Estimated Pickup Time)</DateTimeStatus>}
                </>
              )}
            </DetailsListItem>
            <DetailsListItem title="Delivery Location">
              {isEmpty(data.deliveryLocation) ? (
                EMPTY_FIELD_PLACEHOLDER
              ) : (
                <>
                  {data.deliveryLocation.name}
                  <br />
                  {data.deliveryLocation.street} {data.deliveryLocation.suburb} {data.deliveryLocation.state}{' '}
                  {data.deliveryLocation.postcode}
                </>
              )}
            </DetailsListItem>
            <DetailsListItem title="Delivery Time">
              {isEmpty(deliveryTime) ? (
                EMPTY_FIELD_PLACEHOLDER
              ) : (
                <>
                  {deliveryTime}
                  {!data.actualDeliveryDateTime && <DateTimeStatus>(Estimated Delivery Time)</DateTimeStatus>}
                </>
              )}
            </DetailsListItem>
          </MapContextProvider>
        )}
      </Content>
      <NumbersModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        referenceNumbers={referenceNumbers}
        title="Customer reference numbers"
      />
    </>
  );
};

const PageTitle = styled(H2)`
  margin: 0;

  ${desktopOnly} {
    ${({ rightAligned }) => rightAligned && `text-align: right;`};
  }

  small {
    display: block;
    margin-top: 4px;
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    font-size: ${({ theme }) => theme.fontSize.xSmall};
    color: ${({ theme }) => theme.colors.greyFour};
  }
`;

const DateTimeStatus = styled.em`
  margin-left: ${(props) => props.theme.space.xxs};
  color: ${(props) => props.theme.colors.greyFour};
  font-style: normal;
`;

const StyledDetailsTitle = styled(DetailsTitle)`
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyTwo};
`;

const ObservedTrackAndTraceDetail = observer(TrackAndTraceDetail);
export { ObservedTrackAndTraceDetail as TrackAndTraceDetail };
