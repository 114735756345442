import ReactGA from 'react-ga4';

const sendPageView = (page, search) => {
  // track the initial page load
  ReactGA.send({
    hitType: 'pageview',
    page,
    search: search || '',
  });
};

const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);

  // Track on initial hit
  const page = window.location.pathname;
  const { search } = window.location;

  sendPageView(page, search);
};

const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export { initGA, sendPageView, trackEvent };
