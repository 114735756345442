import { createPortal } from 'react-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, StatusLabel } from '../../ui-library';
import { Link } from '../../link';

const StatusCell = ({ status, time, shipmentId }) => {
  const [nestedLinkContainer, setNestedLinkContainer] = useState();

  return (
    <>
      <StatusLabel type="booking" variant={status}>
        {status}
      </StatusLabel>
      {time && (
        <Box mt={{ md: '0.5rem' }} color="greyFour">
          {time}
        </Box>
      )}
      <div ref={setNestedLinkContainer} />
      {/* Portal required to avoid warning around nesting Links within a Link */}
      {shipmentId &&
        !!nestedLinkContainer &&
        createPortal(
          <Box mt={{ md: '0.5rem' }} color="infoNine">
            {/* Stop propagation to prevent parent link from triggering */}
            <Link target="_blank" to={`/track?keyword=${shipmentId}`} onClick={(e) => e.stopPropagation()}>
              {shipmentId}
            </Link>
          </Box>,
          nestedLinkContainer
        )}
    </>
  );
};

StatusCell.propTypes = {
  shipmentId: PropTypes.string,
  status: PropTypes.string.isRequired,
  time: PropTypes.string,
};

StatusCell.defaultProps = {
  shipmentId: null,
  time: null,
};

export { StatusCell };
