import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ControlledInput } from './controlled-input';
import { ErrorLabel, useFormState } from '../form';

const FileUpload = ({ canUploadMultiple, name, validation }) => {
  const { control, getError } = useFormState();

  const error = getError(name);

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { value = [], onBlur, onChange } }) => (
        <>
          <ControlledInput
            canUploadMultiple={canUploadMultiple}
            value={value}
            onChange={(event) => {
              onChange(event);
              onBlur();
            }}
          />
          {error && <ErrorLabel>{error}</ErrorLabel>}
        </>
      )}
    />
  );
};

FileUpload.propTypes = {
  canUploadMultiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  validation: PropTypes.shape(),
};

FileUpload.defaultProps = {
  canUploadMultiple: false,
  validation: null,
};

export { FileUpload };
