import { PropTypes } from 'prop-types';

import { Button, icons, ShowOn } from '../ui-library';
import { CloseButton } from '../shipment-milestones/styles';
import { useMapState } from './map-context';

const ShowMapComponent = ({ title, onClick }) => {
  const { Cross } = icons;

  const { isFetching } = useMapState();

  if (isFetching) {
    return null;
  }

  return (
    <>
      <ShowOn screenSize="mobile">
        {title === 'Close Map' ? (
          <CloseButton onClick={onClick}>
            <Cross fill="black" />
          </CloseButton>
        ) : (
          <Button variant="secondary" minWidth="165px" mt="1" onClick={onClick}>
            {title}
          </Button>
        )}
      </ShowOn>
      <ShowOn screenSize="desktop">
        <Button variant="secondary" minWidth="165px" mt="4" onClick={onClick}>
          {title}
        </Button>
      </ShowOn>
    </>
  );
};

ShowMapComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
};

export { ShowMapComponent };
