import { createContext, forwardRef, useContext, useImperativeHandle } from 'react';
import { get } from 'lodash';
import { layout, space } from 'styled-system';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../button';
import { ErrorLabel } from './input/error-label';
import { Label } from './label';

const FormContext = createContext();

const Form = forwardRef(({ children, defaultValues, mode, name, onSubmit, validationSchema, ...props }, ref) => {
  const formOptions = {
    defaultValues,
    mode,
  };

  // TODO: remove validationSchema from existing forms
  // this will require passing custom validation on a per-input basis
  if (validationSchema) {
    formOptions.resolver = yupResolver(validationSchema);
  }

  const { control, formState, getValues, handleSubmit, reset, register, setValue, watch, trigger } =
    useForm(formOptions);

  const { errors, isSubmitted, isSubmitting, isDirty } = formState;

  // if we access formState.isValid directly, react-hook-form will display a warning, as `isValid` cannot be used if `mode: onSubmit`
  // as a result, we must access it conditionally based on the mode of the form.
  const isValid = mode === 'onSubmit' ? true : formState.isValid;

  useImperativeHandle(ref, () => ({
    errors,
    getValues,
    isValid,
    reset,
    trigger,
  }));

  return (
    <FormContext.Provider
      value={{
        control,
        errors,
        getValues,
        mode,
        isSubmitting,
        isSubmitted,
        isValid,
        isDirty,
        register,
        reset,
        setValue,
        trigger,
        watch,
      }}
    >
      <StyledForm
        name={name}
        onSubmit={handleSubmit((data, event) => onSubmit(data, event, reset))}
        {...props}
        ref={ref}
        noValidate
      >
        {children}
      </StyledForm>
    </FormContext.Provider>
  );
});

Form.displayName = 'Form';

Form.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.shape(),
  mode: PropTypes.oneOf(['onSubmit', 'onBlur', 'onChange']),
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape(),
};

Form.defaultProps = {
  defaultValues: {},
  mode: 'onSubmit',
  name: 'form',
  onSubmit: () => null,
  validationSchema: null,
};

const useFormState = () => {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('Input components must be used within a Form component.');
  }

  const { control, errors, getValues, isDirty, isSubmitted, isValid, mode, register, reset, setValue, watch, trigger } =
    context;

  const getError = (name) => {
    const { message = null } = get(errors, name, {});
    return message;
  };

  return new Proxy(
    { control, errors, getError, getValues, isDirty, isSubmitted, isValid, register, reset, setValue, watch, trigger },
    {
      get(target, prop) {
        if (prop === 'isValid' && mode === 'onSubmit') {
          // eslint-disable-next-line no-console
          console.warn('isValid cannot be used with mode: onSubmit');
        }

        return Reflect.get(target, prop);
      },
    }
  );
};

const ResetButton = (props) => {
  const { reset } = useContext(FormContext);

  return <Button {...props} type="reset" onClick={reset} />;
};

const SubmitButton = (props) => {
  const { isSubmitting } = useContext(FormContext);

  return <Button {...props} type="submit" isSubmitting={isSubmitting} />;
};

const StyledForm = styled.form`
  ${layout};
  ${space};

  display: flex;
  flex-direction: column;
`;

export { ErrorLabel, Form, Label, ResetButton, SubmitButton, useFormState };
