import { ifProp } from 'styled-tools';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from '../../link';
import { Privilege } from '../../privilege';

const NavigationLink = ({ children, isActive, to, privilegeName }) => (
  <Privilege name={privilegeName}>
    <StyledLink to={to} isActive={isActive} data-testid={`link-${to}`}>
      {children}
    </StyledLink>
  </Privilege>
);

// eslint-disable-next-line no-unused-vars
const StyledLink = styled(({ isActive, ...props }) => <Link {...props} />)`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.space.xxs} 0;
  opacity: ${ifProp('isActive', 1, 0.7)};
  border-bottom: ${({ theme }) => ifProp('isActive', `2px solid ${theme.colors.primaryFour}`, `2px solid transparent`)};
  text-decoration: none;
  margin-right: ${({ theme }) => theme.space.md};

  :hover {
    opacity: 1;
  }
`;

NavigationLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  privilegeName: PropTypes.string,
};

NavigationLink.defaultProps = {
  privilegeName: null,
};

export { NavigationLink };
